<template>
  <div class="col-md-12 mb-4">
    <div style="position: relative;">
      <h3>Manage Section C</h3>
      <i class="fa fa-times close cursor-pointer" @click="onClosed()" style="position: absolute; right: 0; top: 0; cursor: pointer;"></i>
    </div>
      <hr>
       
    <h4>Section C: Performance Review Questions</h4>
    <!-- Table section -->
    <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
      <table class="table table-bordered training-plan">
        <thead>
          <tr>
            <th>Questions</th>
            <th>Edit</th>
            <th>Delete</th>
            <th></th> <!-- Add an empty column for the plus button -->
          </tr>
        </thead>
        <tbody>
          <template v-for="(question, questionIndex) in sectionCData" :key="questionIndex">
            <!-- Main question row -->
            <tr>
              <td class="question-cell"  style="word-wrap: break-word; max-width: 1%; white-space: normal;">
                <template v-if="editQuestionIndex !== questionIndex">
                  <strong>{{ question.tableDataHeader }}</strong>
                </template>
                <template v-else>
                  <input type="text" v-model="question.tableDataHeader" placeholder="Enter main question">
                </template>
              </td>
              <td>
                <button @click="editMainQuestion(questionIndex)">
                  {{ editQuestionIndex === questionIndex && editContentIndex === null ? 'Save' : 'Edit' }}
                </button>
              </td>
              <td class="text-center">
                <img
                  class="cursor-pointer"
                  src="../../assets/img/delete.png"
                  alt="Delete"
                  @click="deleteMainQuestion(questionIndex)"
                />
              </td>
              <td>
                <button @click="addingSubQuestionIndex = questionIndex">+</button>
              </td>
            </tr>
            <!-- Sub-question rows -->
            <tr v-if="addingSubQuestionIndex === questionIndex && !editQuestionIndex">
              <td colspan="4">
                <input type="text" v-model="newSubQuestion" placeholder="Enter new sub-question" ref="newSubQuestionInput">
                <!-- Display error message only when Save button is clicked and newSubQuestion is empty -->
                <span v-if="showSubQuestionError && !newSubQuestion" style="color: red;">Sub-question is required</span>
                <button @click="saveNewSubQuestion(questionIndex)" style="margin-left: 10px;">Save</button>
     <button @click="addingSubQuestionIndex = null" style="margin-left: 10px;">Cancel</button>

              </td>
            </tr>
           <tr v-for="(content, contentIndex) in question.contents" :key="contentIndex">
  <td class="content-cell" style="word-wrap: break-word; max-width: 1%; white-space: normal;">
    <template v-if="editContentIndex === contentIndex && editQuestionIndex === questionIndex">
      <!-- Input field for editing sub-question -->
      <input type="text" v-model="question.contents[contentIndex]" placeholder="Enter sub-question">
    </template>
    <template v-else>
      <!-- Display sub-question -->
      {{ content }}
    </template>
  </td>
  <td>
    <!-- Edit button for sub-question -->
    <button @click="editSubQuestion(questionIndex, contentIndex)">
      {{ editContentIndex === contentIndex && editQuestionIndex === questionIndex ? 'Save' : 'Edit' }}
    </button>
  </td>
  <td class="text-center">
    <!-- Render delete button for all sub-question rows -->
    <img
      class="cursor-pointer"
      src="../../assets/img/delete.png"
      alt="Delete"
      @click="deleteSubQuestion(questionIndex, contentIndex)"
    />
  </td>
  <td></td> <!-- Empty column for alignment on sub-questions -->
</tr>

          </template>
        </tbody>
      </table>
    </div>
 <div class="mt-4">
  <!-- <h4 v-if="editQuestionIndex !== null">Edit Main Question</h4> -->
  <h4 >Add New Main Question</h4>
  <div style="display: inline-block;">
    <label>Main Question:</label>
    <!-- Increase the width of the input field -->
    <input type="text" v-model="newMainQuestion" placeholder="Enter main question" style="width: 300px;">
    <!-- Error message for required field -->
    <span v-if="showMainQuestionError && !isValidMainQuestion()" style="color: red;">Main question is required</span>
  </div>
  <!-- Add margin to the button -->
  <button class="btn btn-primary mt-" style="display: inline-block; margin-left: 10px;" @click="addNewMainQuestion()">
   Add
  </button>
</div>




    <!-- Update Button -->
    <div class="mt-4">
      <button class="btn btn-success" @click="updateAssessmentTemplate">Update Template</button>
    </div>
  </div>
</template>

<style>
  .question-cell,
  .content-cell {
    max-width: 10%;
    word-wrap: break-word;
    white-space: normal;
   
  }

  .table td,
  .table th {
    vertical-align: top;
  }

  .training-plan thead th {
    white-space: nowrap;
  }

  .cursor-pointer {
    cursor: pointer;
  }
</style>

<script src="./ManageAsesment"></script>
