import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store/index";
import soplibrary from "../components/sop_library/soplibrary.vue";
import examevidence from "../components/super_user_listing/superuserlisting.vue";
import loginfordocument from "../components/loginfordocument/loginfordocument.vue";
import loginforapproval from "../components/loginforapproval/loginforapproval.vue";
import login from "../components/login/login.vue";
import projects from "../components/projects/projects.vue";
import allocateusers from "../components/allocateUsers/allocateprojectusers.vue";
import projectusers from "../components/projectUsersList/projectUsers.vue";
import department from "../components/department/department.vue";
import dashboard from "../components/dashboard/dashboard.vue";
import listproject from "../components/project_list/project_list.vue";
import projectList from "../components/projectList/projectList.vue";
import createProject from "../components/create_project/createProject.vue";
import designation from "../components/designation/designation.vue";
import trainingPlan from "../components/trainingPlan/trainingPlan.vue";
import userlisting from "../components/userlisting/userlisting.vue";
import verifyUser from "../components/validateUser/confirmUserInfo.vue";
import manageuser from "../components/manageUser/manageuser.vue";
import roles from "../components/roles/roles.vue";
import create_organization from "../components/create_organization/create_organization.vue";
import orglisting from "../components/orglisting/orglisting.vue";
import documentview from "../components/documentview/documentview.vue";
import externalDocumentView from "../components/externalDocumentView/externalDocumentView.vue";
import sopdocview from "../components/documentview/sopdocviewer.vue";
import question from "../components/question/question.vue";
import project_summary from "../components/project_summary/project_summary.vue"
import userconfig from "../components/userconfig/userconfig.vue";
import document_approval from "../components/document_approval/document_approval.vue"; import CreateQuestion from "../components/CreateQuestion/CreateQuestion.vue";
import CheckEmail from "../components/ForgotPassword/CheckEmail.vue";
import approverTestResult from "../components/approverTestResult/approverTestResult.vue";
import ResetPassword from "../components/ForgotPassword/ResetPassword.vue";
import configure_exam from "../components/configure_exam/configure_exam.vue";
import profile_settings from "../components/profile_settings/profile_settings.vue";
import verifyDoc from "../components/documentVerification/verifyDoc.vue";
import myQuestion from "../components/myQuestion/myQuestion.vue";
import ExamResult from "../components/ExamResult/ExamResult.vue";
import screen from "../components/screen/screen.vue";
import noprivilege from "../components/noprivilegepage/noprivilege.vue";
import designationScreenPrivileges from "../components/DesignationScreenPrivileges/DesignationScreenPrivileges.vue"
import already_verified from "../components/already_verified/already_verified.vue"
import createunittest from "../components/Unit_test_document/Unit_test.vue"
import createcodereview from "../components/CodeReviewDocument/CodeReview.vue"
import doctemplate from "../components/document_template/doctemplate.vue";
import doctemplatecreate from "../components/document_template_create/doctemplatecreate.vue"
import sop_acknoledgment from "../components/SOP_Acknowledgment/SOP_AcknowledgmentForm.vue"
import documentList from "../components/documentList/documentList.vue"
import digitalSignPopup from "../components/DigitalSignPopUp/DigitalSignPopUp.vue"
import generalFiles from "../components/generalFiles/generalFiles.vue"
import testList from "../components/testList/testList.vue"
import finalReport from "../components/finalReport/finalReport.vue"
import documentTypeList from "../components/DocumentTypeListing/documentTypeListing.vue";
import editunittest from "../components/Edit_Unit_test_document/editUnitTest.vue"
import editcodereview from "../components/Edit_code_review/editCodeReview.vue"
import versioncodereview from "../components/Version_code_review/versionCodeReview.vue"
import versionunittest from "../components/Version_unit_test/versionUnitTest.vue"
import administration from "../components/administration/administration.vue"
import training from "../components/training/training.vue"
import sharedfolder from "../components/sharedfolders/shairedFolder.vue"
import configProcessTraining from "../components/configProcessTraining/configProcessTraining.vue"
import InvalidSession from "../components/ValidationPages/InvalidSession.vue"
import PasswordResetSuccess from "../components/ValidationPages/PasswordResetSuccess.vue"
import controlRecords from "../components/control_records/controlRecords.vue"
import manageTrainingPlan from "../components/manageTrainingPlan/manageTrainingPlan.vue"
import Assessment from "../components/Assessment/Assessment.vue"
import createAssesment from "../components/createAssesment/createAssesment.vue"
import myAssesment from "../components/myAssesment/myAssesment.vue"
import empAssesment from "../components/empAssesment/empAssesment.vue"
import TlAssesment from "../components/TlAssesment/TlAssesment.vue"
import ManageTlAsesment from "../components/ManageTlAsesment/ManageTlAsesment.vue"
import HrAssesment from "../components/HrAssesment/HrAssesment.vue"
import ManageAsesment from "../components/ManageAsesment/ManageAsesment.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/configProcessTraining",
    name: "configProcessTraining",
    component: configProcessTraining,
    meta: { requiredAuth: true, roleprivilegedata: "Training Config Process Training View," },
    props: true,
  },
  {
    path: "/controlRecords/:id/:docId",
    name: "controlRecords",
    component: controlRecords,
    props: true,
  },
  {
    path: "/sharedfolder",
    name: "sharedfolder",
    component: sharedfolder,
    meta: { requiredAuth: true },
    props: true,
  },
  {
    path: "/training",
    name: "training",
    component: training,
    meta: { requiredAuth: true, roleprivilegedata: "Main Menu Training," },
    props: true,
  },
  {
    path: "/administration",
    name: "administration",
    component: administration,
    props: true,
  },
  {
    path: "/versioncodereview/:projectsName/:folderId/:projectId/:codeReviewId/:versionId",
    name: "versioncodereview",
    component: versioncodereview,
    props: true,
  },
  {
    path: "/versionunittest/:projectsName/:folderId/:projectId/:unitTestId/:versionId",
    name: "versionunittest",
    component: versionunittest,
    props: true,
  },
  {
    path: "/editcodereview/:projectsName/:folderId/:projectId/:codeReviewId",
    name: "editcodereview",
    component: editcodereview,
    props: true,
  },
  {
    path: "/editunittest/:projectsName/:folderId/:projectId/:unitTestId",
    name: "editunittest",
    component: editunittest,
    props: true,
  },
  {
    path: "/createcodereview/:projectsName/:folderId/:projectId",
    name: "createcodereview",
    component: createcodereview,
    props: true,
  },
  {
    path: "/createunittest/:projectsName/:folderId/:projectId",
    name: "createunittest",
    component: createunittest,
    props: true,
  },
  {
    path: "/documenttypelist",
    name: "documentTypeList",
    component: documentTypeList,
    meta: { roleprivilegedata: "Administration Config Bookmark (Sign) View," },
    props: true,
  },
  {
    path: "/generalFiles",
    name: "generalFiles",
    component: generalFiles,
    meta: { requiredAuth: true, roleprivilegedata: "Main Menu My Files," },
    props: true,
  },
  {
    path: "/documentList/:projectId/:projectsName",
    name: "documentList",
    component: documentList,
    props: true,
  },
  {
    path: "/doclogin",
    name: "loginfordocument",
    component: loginfordocument,
  },
  {
    path: "/soplibrary",
    name: "soplibrary",
    component: soplibrary,
    meta: { requiredAuth: true, roleprivilegedata: "Training Training Acknowledgement View," },
  },
  {
    path: "/examevidence",
    name: "examevidence",
    component: examevidence,
    meta: { requiredAuth: true, roleprivilegedata: "Training Online Test View," },
  },
  {
    path: "/approvallogin",
    name: "loginforapproval",
    component: loginforapproval,
  },
  {
    path: "/noprivilege",
    name: "noprivilege",
    component: noprivilege,
    meta: { requiredAuth: true },
  },
  {
    path: "/doctemplate",
    name: "doctemplate",
    component: doctemplate,
    meta: { requiredAuth: true, roleprivilegedata: "Administration Config Document Template View," },
  },
  {
    path: "/doctemplatecreate/:id",
    name: "doctemplatecreate",
    component: doctemplatecreate,
    props: true,
    meta: { requiredAuth: true },
  },
  {
    path: "/allocateusers/:projectuserid",
    name: "allocateusers",
    component: allocateusers,
    meta: { requiredAuth: true },
    props: true,
  },
  {
    path: "/documentview/:documentid/:version",
    name: "documentview",
    component: documentview,
    meta: { requiredAuth: true, roleprivilegedata: "Departments View Document," },
    props: true,
  },
  {
    path: "/externalDocumentView/:documentid/:version",
    name: "externalDocumentView",
    component: externalDocumentView,
    meta: { requiredAuth: true, roleprivilegedata: "Departments View Document," },
    props: true,
  },
  {
    path: "/sopdocview/:documentid",
    name: "sopdocview",
    component: sopdocview,
    meta: { requiredAuth: true },
    props: true,
  },
  {
    path: "/question/:documentid",
    name: "question",
    component: question,
    meta: { requiredAuth: true },
    props: true,
  },
  {
    path: "/confirmUserInfo/:token",
    name: "confirmUserInfo",
    component: verifyUser,
    props: true,
  },
  {
    path: "/ExamResult/:documentid",
    name: "ExamResult",
    component: ExamResult,
    meta: { requiredAuth: true },
    props: true,
  },
  {
    path: "/projectusers",
    name: "projectusers",
    component: projectusers,
    meta: { requiredAuth: true },
  },
  {
    path: "/approverTestResult",
    name: "approverTestResult",
    component: approverTestResult,
    meta: { requiredAuth: true, roleprivilegedata: "Test Approvals View," },

  },
  {
    path: "/createproject/:projectid",
    name: "createproject",
    component: createProject,
    props: true,
  },
  {
    path: "/manageuser/:userId",
    name: "manageuser",
    component: manageuser,
    meta: {
      requiredAuth: true,
      add: "Users Create User",
      edit: "Users Edit User", roleprivilegedata: "Users Create User,"
    },
    props: true,
  },
  {
    path: "/CreateQuestion/:documentid/:id",
    name: "CreateQuestion",
    component: CreateQuestion,
    meta: {
      requiredAuth: true,
      add: "Departments Create Question",
      edit: "Departments Edit Question"
    },
    props: true,

  },
  {
    path: "/myQuestion/:configId/:usersExamId",
    name: "myQuestion",
    component: myQuestion,
    meta: { requiredAuth: true, roleprivilegedata: "Training Online Test View," },
    props: true

  },
  {
    path: "/listproject",
    name: "listproject",
    component: listproject,
  },
  {
    path: "/projectList",
    name: "projectList",
    component: projectList,
    meta: { requiredAuth: true, roleprivilegedata: "Main Menu Projects," },

  },
  {
    path: "/projects",
    name: "projects",
    component: projects,
    meta: { requiredAuth: true },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: { requiredAuth: true },
  },
  {
    path: "/designation",
    name: "designation",
    component: designation,
    meta: { requiredAuth: true, roleprivilegedata: "Designation View," },
  },
  {
    path: "/trainingPlan",
    name: "trainingPlan",
    component: trainingPlan,
    meta: { requiredAuth: true, roleprivilegedata: "Training Training Plan View," },
  },
  {
    path: "/manageTrainingPlan",
    name: "manageTrainingPlan",
    component: manageTrainingPlan,
    meta: { requiredAuth: true, roleprivilegedata: "Training Manage Training Plan View," },
  },
  {
    path: "/roles",
    name: "roles",
    component: roles,
    meta: { requiredAuth: true },
  },
  {
    path: "/Assessment",
    name: "Assessment",
    component: Assessment,
    meta: { requiredAuth: true },
  },
  {
    path: "/createAssesment",
    name: "createAssesment",
    component: createAssesment,
    meta: { requiredAuth: true },
  },
  {
    path: "/myAssesment",
    name: "myAssesment",
    component: myAssesment,
    meta: { requiredAuth: true },
  },
  {
    path: "/ManageAsesment",
    name: "ManageAsesment",
    component: ManageAsesment,
    meta: { requiredAuth: true },
  },
  {
    path: "/empAssesment/:assessmentId",
    name: "empAssesment",
    component: empAssesment,
    meta: { requiredAuth: true },
  },
  {
    path: "/ManageTlAsesment/:assessmentId",
    name: "ManageTlAsesment",
    component: ManageTlAsesment,
    meta: { requiredAuth: true },
  },
  {
    path: "/HrAssesment/:assessmentId",
    name: "HrAssesment",
    component: HrAssesment,
    meta: { requiredAuth: true },
  },
  {
    path: "/TlAssesment",
    name: "TlAssesment",
    component: TlAssesment,
    meta: { requiredAuth: true },
  },
  {
    path: "/userlisting",
    name: "userlisting",
    component: userlisting,
    meta: { requiredAuth: true, roleprivilegedata: "Users View," },
  },
  {
    path: "/department",
    name: "department",
    component: department,
    meta: { requiredAuth: true, roleprivilegedata: "Main Menu Departments," },
  },
  {
    path: "/create_organization/:orgId",
    name: "create_organization",
    component: create_organization,
    meta: { requiredAuth: true },
    props: true,
  },
  {
    path: "/orglisting",
    name: "orglisting",
    component: orglisting,
    meta: { requiredAuth: true },
  },
  {
    path: "/userconfig",
    name: "userconfig",
    component: userconfig,
    meta: { requiredAuth: true },
  },
  {
    path: "/project_summary/:id/:projectType/:projectName",
    name: "project_summary",
    component: project_summary,
    meta: { requiredAuth: true, roleprivilegedata: "Main Menu Projects," },
    props: true,
  },
  {
    path: "/document_approval",
    name: "document_approval",
    component: document_approval,
    meta: { requiredAuth: true, roleprivilegedata: "Main Menu Approvals," },
  },
  {
    path: "/CheckEmail",
    name: "CheckEmail",
    component: CheckEmail,
    meta: { requiredAuth: false },
  },
  {
    path: "/ResetPassword/:token/:type",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { requiredAuth: false },
    props: true
  },
  {
    path: "/configure_exam/:id",
    name: "configure_exam",
    component: configure_exam,
    meta: { requiredAuth: true, roleprivilegedata: "Training Create Online Test Config," },
    props: true
  },
  {
    path: "/profile_settings",
    name: "profile_settings",
    component: profile_settings,
    meta: { requiredAuth: true },
    props: true
  },
  {
    path: "/verifydocument/:documentid",
    name: "verifyDocument",
    component: verifyDoc,
    meta: { requiredAuth: false },
    props: true,
  },
  {
    path: "/screen",
    name: "screen",
    component: screen,
    meta: { requiredAuth: true, roleprivilegedata: "Screens View," },
  },
  {
    path: "/DesignationScreenPrivileges/:designationId/:designationName",
    name: "designationScreenPrivileges",
    component: designationScreenPrivileges,
    meta: { requiredAuth: true, roleprivilegedata: "Designation View Privilege," },
    props: true,
  },
  {
    path: "/already_verified",
    name: "already_verified",
    component: already_verified,
    meta: { requiredAuth: false },
    props: true,
  },
  {
    path: "/sop_acknoledgment/:ackId/:SignPerson",
    name: "sop_acknoledgment",
    component: sop_acknoledgment,
    meta: { requiredAuth: true },
    props: true,
  },
  {
    path: "/testList",
    name: "testList",
    component: testList,
    meta: { requiredAuth: false, roleprivilegedata: "Training Config Online Test View," },
    props: true,
  },
  {
    path: "/finalReport/:id",
    name: "finalReport",
    component: finalReport,
    meta: { requiredAuth: false },
    props: true,
  },
  {
    path: '/PasswordResetSuccess',
    name: 'PasswordResetSuccess',
    component: PasswordResetSuccess,
  },
  {
    path: '/InvalidSession',
    name: 'InvalidSession',
    component: InvalidSession,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,

});
router.afterEach(async function (to, from) {
  const auth = await store.getters.getAuthorize;
  const privilegevalues = await store.getters.getRolesprivilegeData;
  // console.log("privilegevalues.....", privilegevalues);
  if (auth && from.fullPath === "/" && privilegevalues == "test test,") {
    router.push(`/noprivilege`);
  }
});
router.beforeEach(async (to, from, next) => {
  const auth = await store.getters.getToken;

  const privilegevalues = await store.getters.getRolesprivilegeData;
  const isAuth = await store.getters.getAuthorize;
  const isexternal = await store.getters.getIsexternaluser;
  window.scrollTo(0, 0)
  if (isexternal == "True") {
    console.log("isexternal is true");
    if (auth == null && to.fullPath === "/") {
      return next({ path: "/" });
    }
    else if (auth == null && to.fullPath === "/doclogin") {
      return next({ path: "/doclogin" });
    }
    else if (auth != null && to.fullPath.includes("externalDocumentView")) {
      return next();
    }
    else if (auth != null && to.fullPath != "/sharedfolder") {
      return next({ path: "/sharedfolder" });
    }
  }
  else {
    if (auth != null && to.fullPath === "/" && privilegevalues == "test test,") {
      return next({ path: "/noprivilege" });
    } else if (auth != null && to.fullPath === "/" && privilegevalues != "test test,") {
      return next({ path: "/dashboard" });
    } else if (auth == null && to.meta.requiredAuth) {
      return next({ path: "/" });
    }

    else if (
      auth != null &&
      to.meta.roleprivilegedata && privilegevalues == "test test," &&
      to.fullPath==='/' &&
      !privilegevalues.includes(to.meta.roleprivilegedata)
    ) {
      return next({ path: "/noprivilege" });
    }
    else if (
      auth != null &&
      to.meta.add &&
      !privilegevalues.includes(to.meta.add) &&
      to.params.userId == "0"
    ) {
      return next({ path: "/noprivilege" });
    }
    else if (
      auth != null &&
      to.meta.edit &&
      !privilegevalues.includes(to.meta.edit)
    ) {
      return next({ path: "/noprivilege" });
    }
    else if (
      auth &&
      to.meta.roleprivilage &&
      !privilegevalues.includes(to.meta.roleprivilage)
    ) {
      return next({ path: "/noprivilege" })
    }
  }
  return next();
});
export default router;
