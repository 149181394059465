<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0 align-self-center">Manage Assessment & Record</h2>
    </div>
    <!-- <div class="col-2 col-md-2 align-self-center">
      <i
        class="fa fa-times close cursor-pointer"
        @click="goBack"
      ></i>
    </div> -->
  </div>
  <div class="wrapper wrapper-content">
    <div class="tabs-container col-md-12">
    <ul class="nav nav-tabs" role="tablist">
  <li>
    <a class="nav-link active" data-toggle="tab" href="#tab-1" >
      My Assessment
    </a>
  </li>
  <li>
    <a class="nav-link" data-toggle="tab" href="#tab-2"  @click="handleTabClick('MyAssesment')">
      Assessment for Approval
    </a>
  </li>
    <li>
   
          <a class="nav-link" v-if="designationPrivileges.includes('Assessment Assign Assessment')" data-toggle="tab" href="#tab-3" @click="handleTabClick('createAssesment')">
            Assign Assessment
          </a>
        </li>
</ul>

      <div class="tab-content background-white">
        <div role="tabpanel" id="tab-1" class="tab-pane active" :key="1">
          <myAssesment
            ref="myAssesment"
            :refreshFlag="refreshFlags.myAssesment"
          />
        </div>
        <div role="tabpanel" id="tab-2" class="tab-pane">
          <TlAssesment
          ref="TlAssesment"
            :refreshFlag="refreshFlags.TlAssesment" />
        </div>
        <div role="tabpanel" id="tab-3" class="tab-pane">
          <createAssesment ref="createAssesment"
            :refreshFlag="refreshFlags.createAssesment"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./Assessment.js">
</script>
    
<style>
.background-white {
  background-color: hsl(0, 0%, 100%);
}
</style>