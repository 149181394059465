/* eslint-disable */
import store from "../../store/index";
import jwt_decode from "jwt-decode";
import axios from "axios";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import VPagination from "@hennge/vue3-pagination";
import Multiselect from "@vueform/multiselect";
export default {
  components: { VPagination, Multiselect },
  props: {
    refreshFlag: Boolean // Define a prop to trigger refresh
  },
  watch: {
    refreshFlag(newVal) {
      if (newVal) {
        this.refreshContent();
      }
    }
  },
  computed: {
    dateToday() {
      // Calculate the minimum allowed date (today's date) with time zone information
      const today = new Date();
      const year = today.getUTCFullYear();
      const month = String(today.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const day = String(today.getUTCDate()).padStart(2, '0');
      const hours = String(today.getUTCHours()).padStart(2, '0');
      const minutes = String(today.getUTCMinutes()).padStart(2, '0');
      const seconds = String(today.getUTCSeconds()).padStart(2, '0');
      const milliseconds = String(today.getUTCMilliseconds()).padStart(3, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    },
  },
  data() {
    return {
      designationPrivileges:[],
      showMoreStatesSkills: {},
      showMoreStates: {},
      searchActualstartdate: "",
      searchActualenddate: "",
      searchPlannedstartdate: "",
      searchPlannedenddate: "",
      searchStatus: "All",
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      fromDate: "",
      toDate: "",
      idtoken: "",
      totalPages: 0,
      currentPage: 1, 
      pageSize: 10,
      pageIndex: 1 ,
      sortProperty: "createdAt",
      sortorder: 1,
      tableview_heading: "Training Plan and Record for",
      username: "username",
      data: [],
      showSidePopup: [false],
      showDateBlock: false,
    
      createTestPlan: {
        userIds: [],
        trainingId: "",
        skills: "",
        details: "",
        plannedStartDate: null,
        plannedEndDate: null,
        trainingMethod: "Self",
        trainedBy: "",
      },
      errors: {
        designation: false,
        users: false,
        dateOfReview: false,
        teamLead: false,
      },
      documentType:"",
      employeeId:"",
      emps: [
       
      ],
      empdetailsTable: {
        teamLead: "",
        SecondaryTeamLead:"",
        empName: "",
        designation: [],
        employeeId: "",
        dateOfReview: ""
      },
      assessmentData: null,
      
      errors: [false, false, false, false, false, false, false],
      isEdit: false,
      userList: "",
      selectedTraining: "",
      showModal: false,
      trainerList: [],
      designationList: [],
      designation: [],
    }
  },
  async mounted() {
    this.idtoken = store.getters.getToken;
   
    try {
      const decodedToken = jwt_decode(this.idtoken);
      this.userId = decodedToken.user_id;
      console.log("Editing assessment with ID:", this.userId);
    await this.getAsesmentList();
  } catch (error) {
    console.error("Error fetching assessment data:", error);
  }
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
 
    await this.listusers();
    await this.getalldesignation();
   this.getAsesmentList();
  
  },

  methods: {refreshContent() {
    console.log("Refreshed addTraining")
  
   },
    truncateTextSkills(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
    toggleShowMoreSkills(index) {
      this.showMoreStatesSkills = {
        ...this.showMoreStatesSkills,
        [index]: !this.showMoreStatesSkills[index]
      };
    },
    truncateText(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
    toggleShowMore(index) {
      this.showMoreStates = {
        ...this.showMoreStates,
        [index]: !this.showMoreStates[index]
      };
    },
    formatDateTime(dateTimeString) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const dateTime = new Date(dateTimeString);
      const day = dateTime.getDate().toString().padStart(2, '0');
      const month = months[dateTime.getMonth()];
      const year = dateTime.getFullYear();
      const hours = dateTime.getHours().toString().padStart(2, '0');
      const minutes = dateTime.getMinutes().toString().padStart(2, '0');

      return `${day}${month}${year} | ${hours}:${minutes}`;
    },
  

    NeededDateRange(startDate, endDate) {
      if (startDate === null || endDate === null) {
        return ''; // Return an empty string if either start or end date is null
      }

      const start = new Date(startDate);
      const end = new Date(endDate);

      const options = { day: '2-digit', month: 'short', year: 'numeric' };

      if (start.getTime() === end.getTime()) {
        return this.formatDate(start, options).toUpperCase(); // Return single date
      } else {
        const formattedStart = this.formatDate(start, options);
        const formattedEnd = this.formatDate(end, options);
        return `${formattedStart.toUpperCase()}\nto\n${formattedEnd.toUpperCase()}`;
      }
    },

    formatDate(date, options) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    dateBlockDisplay() {
      const cardBody = document.getElementById("cardtrainingBody")
      this.showDateBlock = !this.showDateBlock
      if (this.showDateBlock) {
        cardBody.style.height = '94px'
      }
      else {
        cardBody.style.height = '0px'
      }
    },
    async clearValues() {

      this.searchActualstartdate = "";
      this.searchActualenddate = "";
      this.searchPlannedstartdate = "";
      this.searchPlannedenddate = "";
      this.searchStatus = "All";
      await this.onSearchClicked()
    },
    exportToWord() {
      console.log("Export data", this.data);
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageIndex = page;
      this.getTrainingList();
    },
 
    toDatePicker(isoDate) {
      if (isoDate !== null) {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      } else {
        return null;
      }
    },
    async closePopUp() {
      await this.clearCreatePopup();
      this.showModal = false;
      this.errors = [false, false, false, false, false, false, false]
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageIndex = page;
      await this.getAsesmentList();
  },
  minDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  },
    async listusers() {
      // await axios.post(`${this.baseurl}document/trainingplan/traininguser?exceptuserId=${this.createTestPlan.userIds}&Assign=true`, [], this.Head)
    await axios.post(`${this.baseurl}document/projectdevdocumenttemplate/asesmentuser?exceptuserId=${this.emps}&Assign=true`, [], this.Head)

      .then((res) => {
          this.userList = res.data;
          console.log("list users", res)
        })
        .catch(err => console.log("error in list user", err))

    },
    async clearCreatePopup() {
      this.empdetailsTable = {
        designation: [],
        teamLead: "",
        SecondaryTeamLead:"",
       
        dateOfReview:"",
       
       
      }
      this.emps=[],
      this.designation = [];
    },
    editAssessment(assessmentId) {
      
      window.location.href = '/HrAssesment/' + assessmentId;
  },
  
    async createAssesment() {
      
          try {
              const response = await axios.post(`${this.baseurl}document/projectdevdocumenttemplate/createassesmenthr`, {
                // const response = await axios.post(`https://localhost:5001/projectdevdocumenttemplate/createassesmenthr`, {    
              "documentType": this.documentType,
                  "Employees"  : this.emps,
                  "empdetailsTable": {
                      "teamLead": this.empdetailsTable.teamLead,
                      "SecondaryTeamLead":this.empdetailsTable.SecondaryTeamLead,
                      "empName": "", 
                      "designation": "",
                      "empId": "",
                      "dateOfReview": this.empdetailsTable.dateOfReview
                  },
                  "tableLists": [
                      {
                          "tableHeader": "",
                          "tableContents": [
                              {
                                  "tableDataHeader": "",
                                  "tableData": [
                                      ""
                                  ],
                                  "contentHeading": [
                                      ""
                                  ]
                              }
                          ]
                      }
                  ],
                  "isSigned": false,
                  "signReferanceId": "",
                  "documentId": "",
                  "documentName": ""
              }, this.Head);
              
              alert("Assessment created successfully.");
              this.closePopUp();
               this.getAsesmentList();
              console.log("asesment", response);
          } catch (error) {
              console.log("error in assesment", error);
              alert("Something went wrong!");
          }
      
  },
 
  async getAsesmentList(pageIndex, pageSize) {
    try {
      const response = await axios.get(
        `${this.baseurl}document/projectdevdocumenttemplate/getasesmentlist?Id=${this.userId}&tab=Assign&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=CreatedAt&SortOrder=1`,
        // `https://localhost:5001/projectdevdocumenttemplate/getasesmentlist?Id=${this.userId}&tab=Assign&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=CreatedAt&SortOrder=1`,

        {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        }
      );
      this.totalPages = Math.ceil(response.data.totalCount / this.pageSize);
  
     
      this.assessmentData = response.data.entities;
  
    
this.assessmentData.forEach(assessment => {
  const dateOfReview = new Date(assessment.item.empdetailsTable.dateOfReview);
  const day = dateOfReview.getDate().toString().padStart(2, '0');
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const month = monthNames[dateOfReview.getMonth()];
  const year = dateOfReview.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  assessment.item.empdetailsTable.dateOfReview = formattedDate;
});

      
    } catch (error) {
      console.error("Error fetching assessment data:", error);
      this.assessmentData = null;
    }
  },
  async deleteAssesment(id) {
    if (confirm("Are you sure you want to delete this Assessment?")) {
      await axios.delete(`${this.baseurl}document/projectdevdocumenttemplate/deleteasesment?Id=${id}`,
      this.Head)
        .then((res) => {
          alert("Deleted successfully")
          console.log("Asesment deleted.", res)
          this.getAsesmentList();

        })
        .catch(err => {
          console.log("error in deleting assessment", err)
          if(err.response.data.messages[0].includes("You are unable to edit a assessment that has already been signed.")){
            alert("You are unable to delete assesment that has already been signed.");
          }
          else{
            alert("Something went wrong!");
          }
          this.closePopUp();
          this.showSidePopup= [false];
        });
    }
  },
  async downloadAssessment(assessmentId) {
    console.log("token", this.idtoken);
    try {
        const response = await axios.post(
            `${this.baseurl}document/projectdevdocumenttemplate/generateasessment?Id=${assessmentId}`, {},
            {
                headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                },
                responseType: 'blob' // Set the responseType here
            }
        );
        console.log("token2", this.idtoken);
        
        let filename = 'assessment_document.docx'; // Default filename
        const contentDisposition = response.headers['content-disposition'];
        
        if (contentDisposition && contentDisposition.includes('filename=')) {
            filename = contentDisposition
                .split('filename=')[1]
                .split(';')[0]
                .replace(/"/g, '');
        }
        
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error("Error downloading assessment document:", error);
        alert("Error downloading assessment document. Please try again.");
    }
},


    async openPopup(id) {
      this.showModal = true;
      if (id != null) {
        this.isEdit = true;
        await this.clearCreatePopup();
        await this.getTrainingById(id);
        this.bindValue(this.selectedTraining);
      }
      else {
        this.isEdit = false;
        this.selectedTraining = "";
        await this.clearCreatePopup();
      }
    },
    manageTemplate() {
     
      this.$router.push('/ManageAsesment');
    },
    async bindValue(item) {
      this.createTestPlan = {
        userIds: [],
        trainingId: item.id,
        skills: item.skills,
        details: item.trainingDetails,
        plannedStartDate: this.toDatePicker(item.plannedStartDate),
        plannedEndDate: this.toDatePicker(item.plannedEndDate),
        trainingMethod: item.trainingMethod,
        trainedBy: item.trainedBy,
      }
    },
    
    async saveButtonClickedhr() {
      this.clearErrors();
  
      console.log('Validating fields...');
      console.log('Designations:', this.empdetailsTable?.designation);
      console.log('Users:', this.emps);
      console.log('Date of Review:', this.empdetailsTable?.dateOfReview);
      console.log('Team Lead:', this.empdetailsTable?.teamLead);
  
     
      if (!this.empdetailsTable || !this.empdetailsTable.designation || !this.empdetailsTable.designation.length) {
        this.errors.designation = true;
      }
      if (!this.emps || !this.emps.length) {
        this.errors.users = true;
      }
      if (!this.empdetailsTable || !this.empdetailsTable.dateOfReview) {
        this.errors.dateOfReview = true;
      }
      if (!this.empdetailsTable || !this.empdetailsTable.teamLead) {
        this.errors.teamLead = true;
      }
  
      
      if (Object.values(this.errors).some(error => error)) {
        console.log('Validation errors:', this.errors);
        return; 
      }
  
      try {
        await this.createAssesment();
        this.closePopUp();
      } catch (error) {
        console.error("An error occurred while saving the assessment:", error);
      }
    },
  
      clearErrors() {
        this.errors = {
          designation: false,
          users: false,
          dateOfReview: false,
          teamLead: false,
        };
      },
    async getTrainingById(id) {
      await axios.get(`${this.baseurl}document/trainingplan/gettrainingbyid?trainingId=${id}`, this.Head)
        .then((res) => {
          console.log("selected traing ", res.data);
          this.selectedTraining = res.data;
        })
        .catch(err => console.log("error in get trianing by id", err));
    },
    async onSearchClicked() {
      this.currentPage = 1;
      this.pageIndex = 1;
      await this.getTrainingList();
    },
    async listTrainingUser(designations) {
      // await axios.post(`${this.baseurl}document/trainingplan/traininguser?exceptuserId=${this.empdetailsTable.teamLead}&Assign=false`, designations, this.Head)
   await axios.post(`${this.baseurl}document/projectdevdocumenttemplate/asesmentuser?exceptuserId=${this.empdetailsTable.teamLead}&Assign=false`, designations, this.Head)
 
      .then((res) => {
          this.trainerList = res.data;
          console.log("User list", res.data);
        })
        .catch(err => console.log("error in list traing user", err));
    },
    async getalldesignation() {
      await axios
        .get(`${this.baseurl}configuration/designation/search?status=active&PageSize=10000&SortProperty=displayName`,
          this.Head)
        .then((res) => {
          this.designationList = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async trainerChange() {
      // this.createTestPlan.userIds = [];
      this.emps =[];
      await this.listTrainingUser(this.designation);
    },

    // -- Validations -- 
    async nullValidation(index, value) {
      if (value == "" || value == null) {
        this.errors[index] = true;
      }
    },
    async isDatePrior(index, firstDate, secondDate) {
      if (secondDate < firstDate) {
        this.errors[index] = true;
      }
    },
    async isListEmpty(index, list) {
      if (list.length == 0) {
        this.errors[index] = true;
      }
    },
    async createValidation() {
      this.errors = [false, false, false, false, false, false, false];
      await this.nullValidation(0, this.createTestPlan.skills);
      await this.nullValidation(1, this.createTestPlan.plannedStartDate);
      await this.nullValidation(2, this.createTestPlan.plannedEndDate);
      await this.isDatePrior(3, this.createTestPlan.plannedStartDate, this.createTestPlan.plannedEndDate);
      await this.nullValidation(4, this.createTestPlan.trainingMethod);
      await this.nullValidation(5, this.createTestPlan.trainedBy);
      await this.isListEmpty(6, this.createTestPlan.userIds);
    },
    async updateValidation() {
      this.errors = [false, false, false, false, false, false, false];
      await this.nullValidation(0, this.createTestPlan.skills);
      await this.nullValidation(1, this.createTestPlan.plannedStartDate);
      await this.nullValidation(2, this.createTestPlan.plannedEndDate);
      await this.isDatePrior(3, this.createTestPlan.plannedStartDate, this.createTestPlan.plannedEndDate);
      await this.nullValidation(4, this.createTestPlan.trainingMethod);
      await this.nullValidation(5, this.createTestPlan.trainedBy);
    },
   
      }
      

  

};