import jwt_decode from "jwt-decode";
import store from "../../store/index";
import axios from "axios";
export default {
  data() {
    return {
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      emailId: "",
      templatePassword: "",
      reasons: "",
      reason: "",
      otherReason: "",
      reasonList: [],
      loadingForm: false,
      sectionARows: [
        {
          evaluationArea: "",
          project: "",
          scoreByEmployee: null,
          leadScore: null,
          leadComments: "",
        },
      ],
      sectionBRows: [
        {
          skill: "",
          project: "",
          evidence: "",
          scoreByEmployee: "",
          leadScore: "",
          leadComments: "",
        },
      ],
      signedDate: "",
      disableSaveAndSignButton: false,
      disableSaveButton: false,
      visibleOn: false,
      inputTypes:'password',
      sectionDRows: [{ skill: "", evaluationArea: "", explanation: "" }],
      sectionERows: {
        teamLeadComments: null,
        achievementsFailures: null,
        improvementNeeded: null,
        goalsForNextYear: null,
        discussionWithLead: {
          employeeResponse: null,
          leadResponse: null,
        },
      },
      sectionCData: [],
      employeeResponse: [],
      teamLeadResponse: [],
      validationErrorsC: [],
      popupErrors: {},
      userId: "",
      assessmentData: {},
      employeeSignature: {
        signedBy: "",
        name: "",
        reason: "",
        dateTime: "",
        ref: "",
      },
      leadSignature: {
        signedBy: "",
        name: "",
        reason: "",
        dateTime: "",
        ref: "",
      },
      secleadSignature: {
        signedBy: "",
        name: "",
        reason: "",
        dateTime: "",
        ref: "",
      },
      validationErrorsA: [],
      validationErrorsB: [],
      validationErrorsE: [],

      assessmentTemplate: null,

      showpopup: false,
      inputType: "password",
      loadingSaveBlock: false,
      errorMsg: null,
    };
  },
  computed: {
    isSecondaryTeamLead() {
      return this.userId === this.empDetails?.secondaryTeamLead ;
    },
    isPrimaryTeamLead() {
      return this.userId === this.empDetails?.teamLead;
    },
    disableSaveAndSignButton() {
      const primarystatus = this.assessmentData.assessment.totalStatus?.lead?.status;
      const secondarystatus = this.assessmentData.assessment.totalStatus?.secondaryLead?.status;
    
      if (primarystatus === "Completed") {
        return true;
      }
    
      if (this.isSecondaryTeamLead && secondarystatus !== "Completed") {
        return false;
      }
    
      if (this.isPrimaryTeamLead) {
        if (secondarystatus === "Completed" || !secondarystatus && primarystatus !== "Completed") {
          return false;
        }
      }
    
      return true;
    },
    disableSaveButton() {
      return this.disableSaveAndSignButton(); 
    },
  },
    

  mounted() {
    this.idtoken = store.getters.getToken;
    try {
      const decodedToken = jwt_decode(this.idtoken);
      this.userId = decodedToken.user_id;
      console.log("Editing assessment with ID:", this.userId);
      this.getAssessmentTemplate();
      this.getAssessmentDetailsById();
      console.log("Secondary Team Lead:", this.secondaryTeamLead); 
    } catch (error) {
      console.error("Error fetching assessment data:", error);
    }
  },
  methods: {
    onClosed() {
      this.$router.push({ name: "Assessment" });
    },
    async showPopupvalid(value) {
      this.inputType = value;
      if (this.inputType == "TL_Submit") {
        this.getReasons();
        this.showpopup = true;
      }

      // this.getLocation()

      // this.saveData();
    },
    passwordVisible() {
      this.visibleOn = !this.visibleOn;
      if (this.visibleOn == true) {
        this.inputTypes = "text";
      } else this.inputTypes = "password";
    },

    addNewRow() {
      this.sectionARows.push({
        evaluationArea: "",
        project: "",
        scoreByEmployee: "",
        leadScore: "",
        leadComments: "",
      });
    },
    validateRowsA() {
      let isValid = true;
      this.validationErrorsA = [];
      this.sectionARows.forEach((row, index) => {
        const errors = {};
        if (!row.leadScore || !row.leadComments) {
          isValid = false;
          errors.leadScore = !row.leadScore;
          errors.leadComments = !row.leadComments;
        }
        this.validationErrorsA.push(errors);
      });
      if (!isValid) {
        this.closePopup();
        alert("Please fill in all mandatory fields.");
        window.scroll(0, 100);
      }
      return isValid;
    },
    validateSectionB() {
      let isValid = true;
      this.validationErrorsB = [];

      this.sectionBRows.forEach((row, index) => {
        const errors = {};
        if (!row.leadScore || !row.leadComments) {
          isValid = false;
          errors.leadScore = !row.leadScore;
          errors.leadComments = !row.leadComments;
        }
        this.validationErrorsB.push(errors);
      });
      if (!isValid) {
        this.closePopup();
        // alert("Please fill in all mandatory fields.");
        window.scroll(0, 300);
      }
      return isValid;
    },
    validateRowsC() {
      let isValid = true;
      this.validationErrorsC = [];
      this.sectionCData.forEach((question, index) => {
        const errors = [];
        question.contents.forEach((content, contentIndex) => {
          if (
            !this.sectionCResponses[index].responses[contentIndex].leadScore
          ) {
            isValid = false;
            errors.push(contentIndex);
          }
        });
        if (errors.length > 0) {
          this.validationErrorsC.push(errors);
        } else {
          this.validationErrorsC.push(null);
        }
      });
      return isValid;
    },
    validatePopupFields() {
      let isValid = true;
      this.popupErrors = {};
      if (!this.emailId) {
        isValid = false;
        this.popupErrors.emailId = "Email ID is required.";
      }
      if (!this.templatePassword) {
        isValid = false;
        this.popupErrors.templatePassword = "Password is required.";
      }
      if (!this.reasons) {
        isValid = false;
        this.popupErrors.reasons = "Reason is required.";
      }
      if (this.reasons === "Others" && !this.otherReason) {
        isValid = false;
        this.popupErrors.otherReason = "Other reason is required.";
      }
      return isValid;
    },

    async getReasons() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/getallreason`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.reasonList = res.data;
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    addNewRowB() {
      this.sectionBRows.push({
        skill: "",
        project: "",
        evidence: "",
        scoreByEmployee: "",
        leadScore: "",
        leadComments: "",
      });
    },
    addNewRowD() {
      this.sectionDRows.push({
        Evaluation: "",
        Explanation: "",
      });
    },
    deleteRow(index) {
      this.sectionARows.splice(index, 1);
    },
    deleteRowB(index) {
      this.sectionBRows.splice(index, 1);
    },
    deleteRowD(index) {
      this.sectionDRows.splice(index, 1);
    },
    validateSectionC() {
      let isValid = true;
      this.validationErrorsC = {};
      let sectionCPosition = 0; // Adjust this to the actual position of Section C on the page

      this.sectionCResponses.forEach((question, index) => {
        question.responses.forEach((response, contentIndex) => {
          if (!response.leadScore) {
            isValid = false;
            this.validationErrorsC[`leadScore_${index}_${contentIndex}`] =
              "Please select an option";
          }
        });
      });

      if (!isValid) {
        this.closePopup();
        alert("Please fill in all mandatory fields.");
        window.scroll(0, 600);
      }

      return isValid;
    },
    clearValidationErrorC(index, contentIndex, field) {
      const errorKey = `${field}_${index}_${contentIndex}`;
      if (this.validationErrorsC[errorKey]) {
        delete this.validationErrorsC[errorKey];
      }
    },

    validateSectionE() {
      let isValid = true;
      this.validationErrorsE = {};

      // if (!this.sectionERows.discussionWithLead.leadResponse) {
      //   isValid = false;
      //   this.validationErrorsE.leadResponse = true;
      // }

      if (!this.sectionERows.teamLeadComments) {
        isValid = false;
        this.validationErrorsE.teamLeadComments = true;
      }

      if (!isValid) {
        alert("Please fill in all mandatory fields.");
        this.closePopup();
      }

      return isValid;
    },
    validateRows() {
      let isValid = true;
      this.validationErrors = [];
      this.sectionARows.forEach((row, index) => {
        const errors = {};
        if (!row.leadScore) {
          isValid = false;

          errors.leadScore = !row.leadScore;
        }
        this.validationErrors.push(errors);
      });
      if (!isValid) {
        this.closePopup();
        // window.alert("Please fill in all mandatory fieldsssssssssssss.");

        const firstInvalidIndex = this.validationErrors.findIndex(
          (error) =>
            error.evaluationArea || error.project || error.scoreByEmployee
        );
        if (firstInvalidIndex !== -1) {
          setTimeout(() => {
            const invalidElement = document.querySelector(
              `#evaluationArea-${firstInvalidIndex}`
            );
            if (invalidElement) {
              invalidElement.focus();
            }
          }, 0);
        }
      }
      return isValid;
    },

    async getAssessmentTemplate() {
      try {
        const response = await axios.get(
          `${this.baseurl}document/projectdevdocumenttemplate/getasesmettemplate?Id=a850b5bf-5532-4d6e-9dd8-5adc1987bf20`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );

        this.sectionCData = response.data.tableList.find(
          (item) => item.tableHeader === "Performance Review Questions"
        ).tableData;
        //    console.error(" template:", this.sectionCData);
        // Initialize employeeResponse with the same structure as sectionCData
        this.employeeResponse = this.sectionCData.map((mainQuestion) => {
          return mainQuestion.contents.map(() => ""); // Initialize each inner array with empty strings
        });
        this.teamLeadResponse = this.employeeResponse.map((mainQuestion) => {
          return mainQuestion.map(() => ""); // Initialize each inner array with empty strings
        });
      } catch (error) {
        console.error("Error fetching assessment template:", error);
        // Handle error
      }
    },
    toggleTableHeight() {
      const tableContainer = document.querySelector(".table-container");
      if (
        tableContainer.style.maxHeight === "none" ||
        !tableContainer.style.maxHeight
      ) {
        tableContainer.style.maxHeight = "300px";
      } else {
        tableContainer.style.maxHeight = "none";
      }
    },
    closePopup() {
      this.showpopup = false;
      this.reasons = "";
      this.templatePassword = "";
      this.emailId = "";
      // this.errorMsg = false
      // this.validation = false
      // this.errorMsg = false
      // this.error = [false, false]
    },
    validateAllfields() {
      const rowsValid = this.validateRowsA();
      const sectionBValid = this.validateSectionB();

      // const sectionc = this.validateSectionC();

      return sectionBValid && rowsValid;
    },
    handleInput(index, field) {
      this.clearValidationError(index, field);
      this.clearValidationErrorB(index, field);
      if (field === "leadScore") {
        this.validateScore(index, field);
      }
      if (field === "leadScores") {
        this.validateScore(index, field);
      }
    },
    clearValidationError(index, field) {
      if (this.validationErrorsA[index]) {
        this.validationErrorsA[index][field] = false;
        if (
          Object.values(this.validationErrorsA[index]).every((error) => !error)
        ) {
          this.validationErrorsA.splice(index, 1, {});
        }
      }
    },
    clearValidationErrorB(index, field) {
      if (this.validationErrorsB[index]) {
        this.validationErrorsB[index][field] = false;
        if (
          Object.values(this.validationErrorsB[index]).every((error) => !error)
        ) {
          this.validationErrorsB.splice(index, 1, {});
        }
      }
    },
    validateScore(index, field) {
      // Check if index is within bounds for section A
      if (this.sectionARows && this.sectionARows[index]) {
        // Validate and constrain the value for section A
        let value = this.sectionARows[index][field];
        value = value === "" ? "" : Number(value);
        this.sectionARows[index][field] = value === "" || isNaN(value) || value < 1 || value > 10
          ? value === "" ? "" : Math.max(1, Math.min(value, 10))
          : value;
      } else {
        console.error(`sectionARows or index ${index} is invalid`);
      }
    
      // Check if index is within bounds for section B
      if (this.sectionBRows && this.sectionBRows[index]) {
        // Validate and constrain the value for section B
        let valueB = this.sectionBRows[index][field];
        console.log("index", index, "field", field);
        valueB = valueB === "" ? "" : Number(valueB);
        this.sectionBRows[index][field] = valueB === "" || isNaN(valueB) || valueB < 1 || valueB > 10
          ? valueB === "" ? "" : Math.max(1, Math.min(valueB, 10))
          : valueB;
      } else {
        console.error(`sectionBRows or index ${index} is invalid`);
      }
    },
    
    
    async saveData(input) {
      let isValid = true;
   
      if (input != "Resubmit" && this.userId !== this.secondaryTeamLead) {
        isValid = this.validateAllfields();
      }

      if (!isValid) {
        console.error("Validation failed. Data not saved.");
        return;
      }
      const currentDate = new Date();
      console.log("Assessment Data:", this.employeeName);
      let requestData = {};
      // if (!this.validatePopupFields()) {
      //   return;
      // }

      if (input === "TL_Submit") {
        if(this.userId !== this.secondaryTeamLead){
          this.validateSectionC();
          this.validateSectionE();

        }
      
        if (!this.validatePopupFields()) {
          return;
        }
       
          requestData = {
            InputType: input,
            Email: this.emailId,
            SignerPassword: this.templatePassword,
            Reason: this.otherReason,
            documentType: "Assessment",
  
            sectionA1: {
              rolesResponsibilities: "Roles and responsibilities",
              evaluationAreas: this.sectionARows.map((row) => ({
                evaluation_Area: row.evaluationArea,
                project: row.project,
                employeeScore:
                  row.scoreByEmployee !== undefined &&
                  row.scoreByEmployee !== null
                    ? row.scoreByEmployee.toString()
                    : "",
                leadScore:
                  row.leadScore !== undefined && row.leadScore !== null
                    ? row.leadScore.toString()
                    : "",
  
                leadComments: row.leadComments,
              })),
            },
            sectionB1: {
              skillsTechnologies: this.sectionBRows.map((row) => ({
                skillTechnology: row.skillTechnology,
                project: row.project,
                evidence: row.evidence,
                employeeScore:
                  row.employeeScore !== undefined && row.employeeScore !== null
                    ? row.employeeScore.toString()
                    : "",
                leadScore:
                  row.leadScore !== undefined && row.leadScore !== null
                    ? row.leadScore.toString()
                    : "",
  
                leadComments: row.leadComments,
              })),
            },
            sectionC1: {
              performanceReviewQuestions: {
                mainQuestions: this.sectionCResponses.map((mainQuestion) => ({
                  title: mainQuestion.title,
                  characteristics: mainQuestion.responses.map((response) => ({
                    name: response.name,
                    employeeScore: response.employeeScore,
                    leadScore: response.leadScore,
                  })),
                })),
              },
            },
  
            sectionD1: this.sectionDRows.map((row) => ({
              skill: row.skill,
              evaluationArea: row.evaluationArea,
  
              explanation: row.explanation,
            })),
            sectionE1: {
              teamLeadComments: this.sectionERows.teamLeadComments,
              achievementsFailures: this.sectionERows.achievementsFailures,
              improvementNeeded: this.sectionERows.improvementNeeded,
              goalsForNextYear: this.sectionERows.goalsForNextYear,
              discussionWithLead: {
                employeeResponse:"NO",
                  // this.sectionERows.discussionWithLead.employeeResponse,
                leadResponse: "YES",
              },
            },
           
            totalStatus: {
              emp: {},
           
              lead: this.userId === this.secondaryTeamLead ? {} : {
                signreferenceId: "",
                signedBy: this.teamLeadName,
                name: this.teamLeadName,
                signeddate: currentDate.toISOString(),
                empId: this.empName,
                reason: this.reasons,
                location: "India",
                status: "Completed",
              },
              SecondaryLead: this.userId === this.secondaryTeamLead ? {
                signreferenceId: "",
                signedBy: "",
                name: "",
                signeddate: currentDate.toISOString(),
                empId: "",
                reason: this.reasons,
                location: "India",
                status: "Completed",
              } : {},
            
              
            },
          };
        
        
      }
     
      else {
        requestData = {
          InputType: input,
          Email: this.emailId,
          SignerPassword: this.templatePassword,
          Reason: this.otherReason,
          documentType: "Assessment",

          sectionA1: {
            rolesResponsibilities: "Roles and responsibilities",
            evaluationAreas: this.sectionARows.map((row) => ({
              evaluation_Area: row.evaluationArea,
              project: row.project,
              employeeScore: row.scoreByEmployee,
              leadScore:
                row.leadScore !== undefined && row.leadScore !== null
                  ? row.leadScore.toString()
                  : "",

              leadComments: row.leadComments,
            })),
          },
          sectionB1: {
            skillsTechnologies: this.sectionBRows.map((row) => ({
              skillTechnology: row.skillTechnology,
              project: row.project,
              evidence: row.evidence,
              employeeScore:
                row.employeeScore !== undefined && row.employeeScore !== null
                  ? row.employeeScore.toString()
                  : "",
              leadScore:
                row.leadScore !== undefined && row.leadScore !== null
                  ? row.leadScore.toString()
                  : "",

              leadComments: row.leadComments,
            })),
          },
          sectionC1: {
            performanceReviewQuestions: {
              mainQuestions: this.sectionCResponses.map((mainQuestion) => ({
                title: mainQuestion.title,
                characteristics: mainQuestion.responses.map((response) => ({
                  name: response.name,
                  employeeScore: response.employeeScore,
                  leadScore: response.leadScore,
                })),
              })),
            },
          },

          sectionD1: this.sectionDRows.map((row) => ({
            skill: row.skill,
            evaluationArea: row.evaluationArea,

            explanation: row.explanation,
          })),
          sectionE1: {
            teamLeadComments: this.sectionERows.teamLeadComments,
            achievementsFailures: this.sectionERows.achievementsFailures,
            improvementNeeded: this.sectionERows.improvementNeeded,
            goalsForNextYear: this.sectionERows.goalsForNextYear,
            discussionWithLead: {
              employeeResponse:
                this.sectionERows.discussionWithLead.employeeResponse,
              leadResponse: this.sectionERows.discussionWithLead.leadResponse,
            },
          },
          totalStatus: {
            emp: {}
            
          },
        };
      }

      try {
        const currentUrl = window.location.href;

        const urlParts = currentUrl.split("/");

        const assessmentId = urlParts[urlParts.length - 1];
        console.log("Assessment ID:", this.sectionCResponses.employeeScore);
        const response = await axios.put(
          `${this.baseurl}document/projectdevdocumenttemplate/updateassesmentemp?id=${assessmentId}`,
          // `https://localhost:5001/projectdevdocumenttemplate/updateassesmentemp?id=${assessmentId}`,

          requestData,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Data saved successfully:", response.data);
        window.alert("Assessment updated successfully!");
        this.$router.push({ name: "Assessment" });
      } catch (error) {
        console.error("Error saving data:", error);
        window.alert("Invalid user name or password.");
      }
    },
    async getAssessmentDetailsById() {
      try {
        const currentUrl = window.location.href;
        const urlParts = currentUrl.split("/");
        const assessmentId = urlParts[urlParts.length - 1];
    
        console.log("Fetching assessment details for ID:", assessmentId); // Debug log
    
        const response = await axios.get(
          `${this.baseurl}document/projectdevdocumenttemplate/getasesmentbyid?Id=${assessmentId}`,

          // `https://localhost:5001/projectdevdocumenttemplate/getasesmentbyid?Id=${assessmentId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
    
        if (response.status >= 200 && response.status < 300) {
          const assessmentData = response.data;
          console.log("Assessment data received:", assessmentData); // Debug log
    
          const assessment = assessmentData.assessment;
          const empDetails = assessment.empdetailsTable;
    
          // Extract secondaryTeamLead
          this.secondaryTeamLead = empDetails.secondaryTeamLead;
          console.log("Secondary Team Lead:", this.secondaryTeamLead); // Debug log
    
          if (this.userId === empDetails.empName) {
            this.disableCertainParts = true;
          } else {
            this.disableCertainParts = false;
          }
    
          if (empDetails) {
            this.designation = assessmentData.employeeDesignation;
    
            const dateOfReview = new Date(empDetails.dateOfReview);
            const months = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            this.dateOfReview = `${dateOfReview.getDate()}-${
              months[dateOfReview.getMonth()]
            }-${dateOfReview.getFullYear()}`;
          } else {
            console.error("empdetailsTable is not present in the assessment data.");
          }
    
          this.sectionDRows = assessment.sectionD1 || [];
    
          this.sectionARows = (assessment.sectionA1?.evaluationAreas || []).map(
            (area) => ({
              evaluationArea: area.evaluation_Area,
              project: area.project,
              scoreByEmployee: area.employeeScore,
              leadScore: area.leadScore,
              leadComments: area.leadComments,
            })
          );
    
          this.sectionERows = {
            teamLeadComments: assessment.sectionE1?.teamLeadComments || "",
            achievementsFailures: assessment.sectionE1?.achievementsFailures || "",
            improvementNeeded: assessment.sectionE1?.improvementNeeded || "",
            goalsForNextYear: assessment.sectionE1?.goalsForNextYear || "",
            discussionWithLead: {
              employeeResponse:
                assessment.sectionE1?.discussionWithLead.employeeResponse || "",
              leadResponse:
                assessment.sectionE1?.discussionWithLead.leadResponse || "",
            },
          };
    
          this.sectionBRows = (
            assessment.sectionB1?.skillsTechnologies || []
          ).map((skill) => ({
            skillTechnology: skill.skillTechnology,
            project: skill.project,
            evidence: skill.evidence,
            employeeScore: skill.employeeScore,
            leadScore: skill.leadScore,
            leadComments: skill.leadComments,
          }));
    
          if (assessment.sectionC1) {
            const mainQuestions =
              assessment.sectionC1.performanceReviewQuestions.mainQuestions || [];
            this.sectionCResponses = mainQuestions.map((mainQuestion) => ({
              title: mainQuestion.title,
              responses: (mainQuestion.characteristics || []).map(
                (characteristic) => ({
                  name: characteristic.name,
                  employeeScore: characteristic.employeeScore,
                  leadScore: characteristic.leadScore,
                })
              ),
            }));
          } else {
            this.sectionCResponses = this.sectionCData.map((mainQuestion) => ({
              title: mainQuestion.tableDataHeader,
              responses: mainQuestion.contents.map((content) => ({
                name: content,
                employeeScore: "",
                leadScore: "",
              })),
            }));
          }
    
          this.employeeName = assessmentData.employeeName || "";
          this.teamLeadName = assessmentData.teamLeadName || "";
          this.empName = empDetails.empName || "";
    
          if (assessment.totalStatus) {
            const empSign = assessment.totalStatus.emp;
            if (empSign) {
              this.employeeSignature = {
                signedBy: empSign.signedBy,
                name: empSign.name,
                reason: empSign.reason || "N/A",
                dateTime: new Date(empSign.signeddate).toLocaleString(),
                ref: empSign.signreferenceId,
              };
            }
            const leadSign = assessment.totalStatus.lead;
            if (leadSign) {
              this.leadSignature = {
                signedBy: leadSign.signedBy,
                name: leadSign.name,
                reason: leadSign.reason || "N/A",
                dateTime: new Date(leadSign.signeddate).toLocaleString(),
                ref: leadSign.signreferenceId,
              };
            }
            const secleadSign = assessment.totalStatus.secondaryTeamLead;
            if (secleadSign) {
              this.secleadSignature = {
                signedBy: secleadSign.signedBy,
                name: secleadSign.name,
                reason: secleadSign.reason || "N/A",
                dateTime: new Date(secleadSign.signeddate).toLocaleString(),
                ref: secleadSign.signreferenceId,
              };
            }
          }
          // if (
          //   assessmentData.assessment.totalStatus.lead.status == "Completed"
          // ) {
          //   this.disableSaveAndSignButton = true;
    
          //   this.disableSaveButton = true;
          // }
        } else {
          throw new Error("Failed to fetch assessment details");
        }
      } catch (error) {
        console.error("Error fetching assessment details:", error);
      }
      console.log("details:", this.sectionERows);
    }
    
  },
};
