import jwt_decode from "jwt-decode";
import store from "../../store/index";
import axios from "axios";
export default {
  data() {
    return {
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      loadingForm: false,
      sectionARows: [
        {
          evaluationArea: "",
          project: "",
          scoreByEmployee: null,
          leadScore: null,
          leadComments: "",
        },
        // Add more rows as needed
      ],
      sectionBRows: [
        {
          skill: "",
          project: "",
          evidence: "",
          scoreByEmployee: "",
          leadScore: "",
          leadComments: "",
        },
      ],
      signedDate: "",
      disableSaveAndSignButton: false,
      disableSaveButton: false,

      sectionDRows: [
        { skill: "", evaluationArea: "", explanation: "" },
        // more rows as needed
      ],
      sectionERows: {
        teamLeadComments: null,
        achievementsFailures: null,
        improvementNeeded: null,
        goalsForNextYear: null,
        discussionWithLead: {
          employeeResponse: null,
          leadResponse: null,
        },
      },
      sectionCData: [],
      employeeResponse: [],
      teamLeadResponse: [],
      userId: "",
      assessmentData: {},
      employeeSignature: {
        signedBy: "",
        name: "",
        reason: "",
        dateTime: "",
        ref: "",
      },
      leadSignature: {
        signedBy: "",
        name: "",
        reason: "",
        dateTime: "",
        ref: "",
      },

      disableCertainParts: true,
      assessmentTemplate: null,

      showpopup: false,
      inputType: "password",
      loadingSaveBlock: false,
      errorMsg: null,
    };
  },

  mounted() {
    this.idtoken = store.getters.getToken;
    try {
      const decodedToken = jwt_decode(this.idtoken);
      this.userId = decodedToken.user_id;
      console.log("Editing assessment with ID:", this.userId);
      this.getAssessmentTemplate();
      this.getAssessmentDetailsById();
    } catch (error) {
      console.error("Error fetching assessment data:", error);
    }
  },
  methods: {
    onClosed() {
      this.$router.push({ name: "Assessment" });
    },
    async showPopupvalid(value) {
      this.inputType = value;
      if (this.inputType == "TL_Submit") {
        this.showpopup = true;
        this.getReasons();
      }

      // this.getLocation()

      // this.saveData();
    },
    passwordVisible() {
      this.visibleOn = !this.visibleOn;
      if (this.visibleOn == true) {
        this.inputType = "text";
      } else this.inputType = "password";
    },

    addNewRow() {
      this.sectionARows.push({
        evaluationArea: "",
        project: "",
        scoreByEmployee: "",
        leadScore: "",
        leadComments: "",
      });
    },
    async getReasons() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/getallreason`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.reasonList = res.data;
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    addNewRowB() {
      this.sectionBRows.push({
        skill: "",
        project: "",
        evidence: "",
        scoreByEmployee: "",
        leadScore: "",
        leadComments: "",
      });
    },
    addNewRowD() {
      this.sectionDRows.push({
        Evaluation: "",
        Explanation: "",
      });
    },
    deleteRow(index) {
      this.sectionARows.splice(index, 1);
    },
    deleteRowB(index) {
      this.sectionBRows.splice(index, 1);
    },
    deleteRowD(index) {
      this.sectionDRows.splice(index, 1);
    },
    async getAssessmentTemplate() {
      try {
        const response = await axios.get(
          `${this.baseurl}document/projectdevdocumenttemplate/getasesmettemplate?Id=a850b5bf-5532-4d6e-9dd8-5adc1987bf20`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );

        this.sectionCData = response.data.tableList.find(
          (item) => item.tableHeader === "Performance Review Questions"
        ).tableData;

        this.employeeResponse = this.sectionCData.map((mainQuestion) => {
          return mainQuestion.contents.map(() => "");
        });
        this.teamLeadResponse = this.employeeResponse.map((mainQuestion) => {
          return mainQuestion.map(() => "");
        });
      } catch (error) {
        console.error("Error fetching assessment template:", error);
        // Handle error
      }
    },
    toggleTableHeight() {
      const tableContainer = document.querySelector(".table-container");
      if (
        tableContainer.style.maxHeight === "none" ||
        !tableContainer.style.maxHeight
      ) {
        tableContainer.style.maxHeight = "300px";
      } else {
        tableContainer.style.maxHeight = "none";
      }
    },
    closePopup() {
      this.showpopup = false;
    },

    async saveData(input) {
      const currentDate = new Date();
      console.log("Assessment Data:", this.employeeName);
      let requestData = {};

      if (input === "TL_Submit") {
        requestData = {
          InputType: input,
          Email: this.emailId,
          SignerPassword: this.templatePassword,
          Reason: this.otherReason,
          documentType: "Assessment",

          sectionA1: {
            rolesResponsibilities: "Roles and responsibilities",
            evaluationAreas: this.sectionARows.map((row) => ({
              evaluation_Area: row.evaluationArea,
              project: row.project,
              employeeScore:
                row.scoreByEmployee !== undefined &&
                row.scoreByEmployee !== null
                  ? row.scoreByEmployee.toString()
                  : "",
              leadScore:
                row.leadScore !== undefined && row.leadScore !== null
                  ? row.leadScore.toString()
                  : "",

              leadComments: row.leadComments,
            })),
          },
          sectionB1: {
            skillsTechnologies: this.sectionBRows.map((row) => ({
              skillTechnology: row.skillTechnology,
              project: row.project,
              evidence: row.evidence,
              employeeScore:
                row.employeeScore !== undefined && row.employeeScore !== null
                  ? row.employeeScore.toString()
                  : "",
              leadScore:
                row.leadScore !== undefined && row.leadScore !== null
                  ? row.leadScore.toString()
                  : "",

              leadComments: row.leadComments,
            })),
          },
          sectionC1: {
            performanceReviewQuestions: {
              mainQuestions: this.sectionCResponses.map((mainQuestion) => ({
                title: mainQuestion.title,
                characteristics: mainQuestion.responses.map((response) => ({
                  name: response.name,
                  employeeScore: response.employeeScore,
                  leadScore: response.leadScore,
                })),
              })),
            },
          },

          sectionD1: this.sectionDRows.map((row) => ({
            skill: row.skill,
            evaluationArea: row.evaluationArea,

            explanation: row.explanation,
          })),
          sectionE1: {
            teamLeadComments: this.sectionERows.teamLeadComments,
            achievementsFailures: this.sectionERows.achievementsFailures,
            improvementNeeded: this.sectionERows.improvementNeeded,
            goalsForNextYear: this.sectionERows.goalsForNextYear,
            discussionWithLead: {
              employeeResponse:
                this.sectionERows.discussionWithLead.employeeResponse,
              leadResponse: this.sectionERows.discussionWithLead.leadResponse,
            },
          },
          totalStatus: {
            emp: {},
            lead: {},
            hr: "Verified",
          },
        };
      } else {
        requestData = {
          InputType: input,
          Email: this.emailId,
          SignerPassword: this.templatePassword,
          Reason: this.otherReason,
          documentType: "Assessment",

          sectionA1: {
            rolesResponsibilities: "Roles and responsibilities",
            evaluationAreas: this.sectionARows.map((row) => ({
              evaluation_Area: row.evaluationArea,
              project: row.project,
              employeeScore: row.scoreByEmployee,
              leadScore:
                row.leadScore !== undefined && row.leadScore !== null
                  ? row.leadScore.toString()
                  : "",

              leadComments: row.leadComments,
            })),
          },
          sectionB1: {
            skillsTechnologies: this.sectionBRows.map((row) => ({
              skillTechnology: row.skillTechnology,
              project: row.project,
              evidence: row.evidence,
              employeeScore:
                row.employeeScore !== undefined && row.employeeScore !== null
                  ? row.employeeScore.toString()
                  : "",
              leadScore:
                row.leadScore !== undefined && row.leadScore !== null
                  ? row.leadScore.toString()
                  : "",

              leadComments: row.leadComments,
            })),
          },
          sectionC1: {
            performanceReviewQuestions: {
              mainQuestions: this.sectionCResponses.map((mainQuestion) => ({
                title: mainQuestion.title,
                characteristics: mainQuestion.responses.map((response) => ({
                  name: response.name,
                  employeeScore: response.employeeScore,
                  leadScore: response.leadScore,
                })),
              })),
            },
          },

          sectionD1: this.sectionDRows.map((row) => ({
            skill: row.skill,
            evaluationArea: row.evaluationArea,

            explanation: row.explanation,
          })),
          sectionE1: {
            teamLeadComments: this.sectionERows.teamLeadComments,
            achievementsFailures: this.sectionERows.achievementsFailures,
            improvementNeeded: this.sectionERows.improvementNeeded,
            goalsForNextYear: this.sectionERows.goalsForNextYear,
            discussionWithLead: {
              employeeResponse:
                this.sectionERows.discussionWithLead.employeeResponse,
              leadResponse: this.sectionERows.discussionWithLead.leadResponse,
            },
          },
          totalStatus: {
            emp: {},
            lead: {},
            hr: "Verified",
          },
        };
      }

      try {
        const currentUrl = window.location.href;

        const urlParts = currentUrl.split("/");

        const assessmentId = urlParts[urlParts.length - 1];
        console.log("Assessment ID:", this.sectionCResponses.employeeScore);
        const response = await axios.put(
          `${this.baseurl}document/projectdevdocumenttemplate/updateassesmentemp?id=${assessmentId}`,
          requestData,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Data saved successfully:", response.data);
        window.alert("Data saved successfully!");
        window.history.back();
      } catch (error) {
        console.error("Error saving data:", error);
        window.alert(
          "An error occurred while saving data. Please try again later."
        );
      }
    },
    async getAssessmentDetailsById() {
      try {
        const currentUrl = window.location.href;
        const urlParts = currentUrl.split("/");
        const assessmentId = urlParts[urlParts.length - 1];

        const response = await axios.get(
          `${this.baseurl}document/projectdevdocumenttemplate/getasesmentbyid?Id=${assessmentId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 300) {
          const assessmentData = response.data;
          console.log("Editing", assessmentData);

          const assessment = assessmentData.assessment;
          const empDetails = assessment.empdetailsTable;

          if (this.userId === empDetails.empName) {
            this.disableCertainParts = true;
          } else {
            this.disableCertainParts = false;
          }

          if (empDetails) {
            this.designation = assessmentData.employeeDesignation;

            const dateOfReview = new Date(empDetails.dateOfReview);
            const months = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            this.dateOfReview = `${dateOfReview.getDate()}-${
              months[dateOfReview.getMonth()]
            }-${dateOfReview.getFullYear()}`;
          } else {
            console.error(
              "empdetailsTable is not present in the assessment data."
            );
          }

          this.sectionDRows = assessment.sectionD1 || [];

          this.sectionARows = (assessment.sectionA1?.evaluationAreas || []).map(
            (area) => ({
              evaluationArea: area.evaluation_Area,
              project: area.project,
              scoreByEmployee: area.employeeScore,
              leadScore: area.leadScore,
              leadComments: area.leadComments,
            })
          );

          this.sectionERows = {
            teamLeadComments: assessment.sectionE1?.teamLeadComments || "",
            achievementsFailures:
              assessment.sectionE1?.achievementsFailures || "",
            improvementNeeded: assessment.sectionE1?.improvementNeeded || "",
            goalsForNextYear: assessment.sectionE1?.goalsForNextYear || "",
            discussionWithLead: {
              employeeResponse:
                assessment.sectionE1?.discussionWithLead.employeeResponse || "",
              leadResponse:
                assessment.sectionE1?.discussionWithLead.leadResponse || "",
            },
          };

          this.sectionBRows = (
            assessment.sectionB1?.skillsTechnologies || []
          ).map((skill) => ({
            skillTechnology: skill.skillTechnology,
            project: skill.project,
            evidence: skill.evidence,
            employeeScore: skill.employeeScore,
            leadScore: skill.leadScore,
            leadComments: skill.leadComments,
          }));

          if (assessment.sectionC1) {
            const mainQuestions =
              assessment.sectionC1.performanceReviewQuestions.mainQuestions ||
              [];
            this.sectionCResponses = mainQuestions.map((mainQuestion) => ({
              title: mainQuestion.title,
              responses: (mainQuestion.characteristics || []).map(
                (characteristic) => ({
                  name: characteristic.name,
                  employeeScore: characteristic.employeeScore,
                  leadScore: characteristic.leadScore,
                })
              ),
            }));
          } else {
            this.sectionCResponses = this.sectionCData.map((mainQuestion) => ({
              title: mainQuestion.tableDataHeader,
              responses: mainQuestion.contents.map((content) => ({
                name: content,
                employeeScore: "",
                leadScore: "",
              })),
            }));
          }

          this.employeeName = assessmentData.employeeName || "";
          this.teamLeadName = assessmentData.teamLeadName || "";
          this.empName = empDetails.empName || "";
          const empSign = assessment.totalStatus.emp;
          if (empSign) {
            this.employeeSignature = {
              signedBy: empSign.signedBy,
              name: empSign.name,
              reason: empSign.reason || "N/A",
              dateTime: new Date(empSign.signeddate).toLocaleString(),
              ref: empSign.signreferenceId,
            };
          }
          const leadSign = assessment.totalStatus.lead;
          if (leadSign) {
            this.leadSignature = {
              signedBy: leadSign.signedBy,
              name: leadSign.name,
              reason: leadSign.reason || "N/A",
              dateTime: new Date(leadSign.signeddate).toLocaleString(),
              ref: leadSign.signreferenceId,
            };
          }
          if (assessmentData.assessment.totalStatus.hr == "Verified") {
            this.disableSaveAndSignButton = true;
            this.disableSaveButton = true;
          }
        } else {
          throw new Error("Failed to fetch assessment details");
        }
      } catch (error) {
        console.error("Error fetching assessment details:", error);
      }
      console.log("details:", this.sectionERows);
    },
  },
};
