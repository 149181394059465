<template>
  <div class="ibox-title style_2 d-flex justify-content-between">
    <!-- <img src="../../assets/img/dm_logo.png" alt="Icon" class="icon-tableview" /> -->
    <h5>Assessment for employees</h5>
    <div class="configure-block">
      <!-- <span class="position-relative filter-block mr-3" @click.prevent="dateBlockDisplay()">
                <img class="cursor-pointer" src="../../assets/img/filter_grey.png" alt="Filter table icon">
                <span class="tool-tip">Filter</span>
            </span> -->
      <span
        class="position-relative filter-block mr-3"
        @click.prevent="openPopup()"
      >
        <img
          class="cursor-pointer"
          src="../../assets/img/add.png"
          alt="Create row icon"
        />
        <span class="tool-tip">Assign Assessment</span>
      </span>
      <span
        class="position-relative filter-block mr-3"
        @click.prevent="manageTemplate()"
      >
        <img
          class="cursor-pointer"
          src="../../assets/img/manage_template.png"
          alt="Manage template icon"
        />
        <span class="tool-tip">Configure Section C</span>
      </span>
    </div>
  </div>
  <div class="form-table-view-container">
    <div class="table-responsive">
      <table class="table table-bordered training-plan">
        <thead>
          <tr>
            <th>Document Type</th>
            <th>Employee</th>
            <th>Designation</th>
            <th>Date of Review</th>
            <th>Team Lead</th>
            <th>TL Status</th>
            <th>Secondary Team Lead</th>
            <th>Secondary TL Status</th>

            <th>HR Status</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(assessment, index) in assessmentData"
            :key="assessment.item.id"
          >
            <td>{{ "Assesment" }}</td>
            <td>{{ assessment.name }}</td>
            <td>{{ assessment.designation }}</td>
            <td>{{ assessment.item.empdetailsTable.dateOfReview }}</td>
            <td>{{ assessment.teamLead }}</td>
            <td>
              {{ assessment.item.totalStatus?.lead?.status || "Pending" }}
            </td>
            <td>
              {{
                assessment.item.empdetailsTable.secondaryTeamLead
                  ? assessment.secondaryTL
                  : "NIL"
              }}
            </td>
            <td>
              {{
                assessment.item.empdetailsTable.secondaryTeamLead
                  ? assessment.item.totalStatus &&
                    assessment.item.totalStatus.secondaryLead
                    ? assessment.item.totalStatus.secondaryLead.status
                    : "Pending"
                  : "NIL"
              }}
            </td>
            <td>{{ assessment.item.totalStatus?.hr || "Pending" }}</td>
            <td class="text-center">
              <div
                class="position-relative cursor-pointer"
                @click.prevent="showSidePopup[index] = !showSidePopup[index]"
                v-on:clickout="showSidePopup[index] = false"
              >
                <i class="fa fa-ellipsis-v"></i>
              </div>
              <div class="plan-side-popup" v-if="showSidePopup[index]">
                <!-- Edit action -->
                <div
                  v-if="
                    assessment.item.totalStatus &&
                    assessment.item.totalStatus.lead &&
                    assessment.item.totalStatus.lead.status === 'Completed'
                  "
                  @click="editAssessment(assessment.item.id)"
                >
                  Edit
                </div>

                <div @click.prevent="deleteAssesment(assessment.item.id)">
                  Delete
                </div>
                <div
                  v-if="
                    assessment.item.totalStatus &&
                    assessment.item.totalStatus.hr === 'Verified'
                  "
                  @click="downloadAssessment(assessment.item.id)"
                >
                  Download
                </div>
              </div>
            </td>

            <!-- Add more table cells for other data as needed -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- <div class="pagination">
  <button @click="currentPage--" :disabled="currentPage === 1">Previous</button>
  <span>{{ currentPage }}</span>
  <button @click="currentPage++" :disabled="currentPage === pageCount">Next</button>
</div> -->
  <div class="row mt-3 mx-0">
    <div class="col-md-12 mx-0 px-0">
      <div
        class="dataTables_paginate paging_simple_numbers pagination float-right"
      >
        <div class="col-md-12 d-flex justify-content-md-end px-0">
          <VPagination
            v-model="currentPage"
            :pages="totalPages"
            @update:modelValue="loadPage"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="create-training-modal" v-if="showModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center"
        >
          <h5 class="my-0">Assign Assessment</h5>
          <span type="button" @click.prevent="closePopUp()"
            ><i class="fa fa-times"></i
          ></span>
        </div>
        <div class="modal-body">
          <div class="form-group row mb-0">
            <div class="col-lg-6">
              <label class="col-form-label"
                >Date of Review <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                v-model="empdetailsTable.dateOfReview"
                type="date"
                class="form-control"
                :min="minDate()"
              />
              <span class="d-block errorClass" v-if="errors.dateOfReview"
                >Please select a date of review</span
              >
            </div>

            <div class="col-lg-6">
              <label class="col-form-label"
                >Primary Team Lead <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <select
                v-model="empdetailsTable.teamLead"
                class="form-control m-b"
                placeholder="Select Training by"
                name="account"
                @change="trainerChange()"
              >
                <option value="" selected>-- Select --</option>
                <option
                  v-for="user in userList"
                  :key="user.userId"
                  :value="user.userId"
                >
                  {{ user.name }}
                </option>
              </select>
              <span class="d-block errorClass" v-if="errors.teamLead"
                >Please select the team lead</span
              >
            </div>
            <div class="col-lg-6">
              <label class="col-form-label">Secondary Team Lead </label>
              <select
                v-model="empdetailsTable.SecondaryTeamLead"
                class="form-control m-b"
                placeholder="Select Training by"
                name="account"
                @change="trainerChange()"
              >
                <option value="" selected>-- Select --</option>
                <option
                  v-for="user in userList"
                  :key="user.userId"
                  :value="user.userId"
                >
                  {{ user.name }}
                </option>
              </select>
              <!-- <span class="d-block errorClass" v-if="errors.teamLead"
                >Please select the team lead</span
              > -->
            </div>

            <div class="col-lg-6">
              <label class="col-form-label"
                >Designation <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <Multiselect
                name="designation"
                valueProp="id"
                mode="multiple"
                placeholder="Select Designations"
                :options="designationList"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-custom-theme"
                @change="listTrainingUser($event)"
                v-model="empdetailsTable.designation"
              >
                <template v-slot:option="{ option }">
                  {{ option.displayName }}
                </template>
              </Multiselect>
              <span class="d-block errorClass" v-if="errors.designation"
                >Please select the designation</span
              >
            </div>

            <div class="col-lg-6" v-if="!isEdit">
              <label class="col-form-label"
                >Users <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <Multiselect
                name="users"
                valueProp="userId"
                mode="multiple"
                placeholder="Select Users"
                :options="trainerList"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-custom-theme"
                v-model="emps"
              >
                <template v-slot:option="{ option }">
                  {{ option.name }}
                </template>
              </Multiselect>
              <span class="d-block errorClass" v-if="errors.users"
                >Please select the user</span
              >
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            @click="closePopUp()"
            class="btn btn-secondary px-4 mr-3"
          >
            Cancel
          </button>
          <button
            type="button"
            @click.prevent="saveButtonClickedhr()"
            class="btn btn-primary px-4"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./createAssesment"></script>
<style scoped>
.ref-label {
  font-size: 0.85em;
}

.signed-by {
  font-family: "Brush Script MT", cursive;
  font-size: 1.5em;
}

.training-table-view {
  display: inline-block;
  text-align: left;
  width: auto;
  color: gray;
  border-collapse: collapse;
  background-color: white;
  margin: 0 auto;
}

.training-table-view td,
th {
  text-align: left;
  border: 1px solid black;
  padding: 10px;
}

.icon-tableview {
  width: 12rem;
  height: 4rem;
  margin-right: 10px;
}

.heading-tableview h2 {
  text-align: center;
  flex: 1;
  font-weight: bold !important;
}

.date-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.date-search input {
  flex: 1;
  margin-right: 10px;
}

.date-search button {
  flex: 1;
  margin-right: 10px;
}

#cardtrainingBody {
  position: relative;
  height: 0px;
  overflow: hidden;
  transition: height 0.35s ease;
}

.plan-side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(109px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup > div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup > div:hover {
  background-color: #e4eaf9;
}

.training-plan thead tr th {
  text-wrap: balance !important;
  vertical-align: middle;
}

.training-plan tr td:nth-child(2),
.training-plan tr td:nth-child(3) {
  max-width: 40ch;
  word-wrap: break-word;
  white-space: break-spaces;
}
training-plan thead tr th {
  background-color: hsl(0, 0%, 100%) !important;
}
.create-training-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.training-plan thead th {
  font-weight: bold;
}

.imp {
  color: #ff000094;
  font-size: 7px;
}

.errorClass {
  color: red;
  font-size: 13px;
}
.filter-block .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.filter-block:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.more {
  font-size: 11px;
  color: #618bd7;
  cursor: pointer;
}

.less {
  font-size: 11px;
  color: #618bd7;
  cursor: pointer;
}
.date-range {
  white-space: pre-line;
}
</style>
