/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import VPagination from "@hennge/vue3-pagination";
import createAssesment from "../createAssesment/createAssesment.vue"
import myAssesment from "../myAssesment/myAssesment.vue"
import TlAssesment from "../TlAssesment/TlAssesment.vue"

export default {
    components:{
        createAssesment,
        myAssesment,
        TlAssesment
      
    },
    data() {
        return {
          designationPrivileges: [],
          refreshFlags: {
            createAssesment: false,
            MyAssesment:false,
            TlAssesment:false
            // approveTrainingPlan: false,
            // addTrainingPlan: false,
            // designationPrivileges:""
          }
        };
      },
      mounted(){
        this.designationPrivileges = store.getters.getRolesprivilegeData.split(",");
        console.log("Privileges are ", this.designationPrivileges)
      },
      methods: {
        handleTabClick(tab) {
          // Set the corresponding flag to true to trigger refresh
          this.refreshFlags[tab] = true;
    
          // Reset the flag after a short delay to allow the refresh to occur
          setTimeout(() => {
            this.refreshFlags[tab] = false;
          }, 100);
        },
        goBack() {
          this.$router.push(`/Assessment`);
        }
        
      }
    };