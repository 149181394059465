<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10 px-0">
      <h2 class="my-0 py-0">Appraisal form</h2>
    </div>
    <div class="col-2 col-md-2 px-0 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click="onClosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <template v-if="!loadingForm">
      <div class="row">
        <div class="col-md-12 mb-4">
          <h3 class="mb-3">Section A: Roles and Responsibilities</h3>
          <p>
            List roles and responsibilities in order of priority. Score your own
            capability or knowledge in the following areas in terms of your
            current role requirements (1-3 = poor, 4-6 = satisfactory, 7-9 =
            good, 10 = excellent). If appropriate, bring evidence with you to
            the appraisal to support your assessment.
          </p>
          <div
            class="add-row-block position-relative cursor-pointer"
            @click="addNewRow"
          >
            <img src="../../assets/img/create.png" alt="Add row" />
            <div class="tool-tip">Add Row</div>
          </div>
          <div class="table-container" style="overflow: auto">
            <table class="table table-bordered">
              <thead class="thead-grey">
                <tr>
                  <th>Evaluation Area (To be completed by Employee)</th>
                  <th>Projects (To be completed by Employee)</th>
                  <th>Score (To be completed by Employee)</th>
                  <th>Lead Score (To be completed by Lead)</th>
                  <th>Comments (To be completed by Lead)</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in sectionARows" :key="index">
                  <td
                    style="
                      word-break: break-word;
                      white-space: normal;
                      width: 20%;
                    "
                  >
                    <textarea
                      class="form-control light-border"
                      v-model="row.evaluationArea"
                      :class="{
                        'is-invalid': validationErrors[index]?.evaluationArea,
                      }"
                      @input="clearValidationError(index, 'evaluationArea')"
                      :id="'evaluationArea-' + index"
                      style="width: 100%"
                      rows="3"
                    ></textarea>
                    <div
                      v-if="validationErrors[index]?.evaluationArea"
                      class="invalid-feedback"
                    >
                      Field is required
                    </div>
                  </td>

                  <td
                    style="
                      word-wrap: break-word;
                      max-width: 1px;
                      white-space: normal;
                    "
                  >
                    <input
                      type="text"
                      class="form-control light-border"
                      v-model="row.project"
                      :class="{
                        'is-invalid': validationErrors[index]?.project,
                      }"
                      @input="clearValidationError(index, 'project')"
                      :id="'project-' + index"
                    />
                    <div
                      v-if="validationErrors[index]?.project"
                      class="invalid-feedback"
                    >
                      Field is required
                    </div>
                  </td>
                  <td
                    style="
                      word-wrap: break-word;
                      max-width: 1px;
                      white-space: normal;
                    "
                  >
                    <input
                      type="number"
                      min="1"
                      max="10"
                      class="form-control light-border"
                      v-model="row.scoreByEmployee"
                      :class="{
                        'is-invalid': validationErrors[index]?.scoreByEmployee,
                      }"
                      @input="handleInput(index, 'scoreByEmployee')"
                      :id="'scoreByEmployee-' + index"
                    />
                    <div
                      v-if="validationErrors[index]?.scoreByEmployee"
                      class="invalid-feedback"
                    >
                      Field is required
                    </div>
                  </td>
                  <td
                    style="
                      word-wrap: break-word;
                      max-width: 1px;
                      white-space: normal;
                    "
                  >
                    <input
                      type="number"
                      min="1"
                      max="10"
                      class="form-control light-border"
                      v-model="row.leadScore"
                      :disabled="disableCertainParts"
                    />
                  </td>
                  <td
                    style="
                      word-wrap: break-word;
                      max-width: 1px;
                      white-space: normal;
                    "
                  >
                    <input
                      type="text"
                      
                      class="form-control light-border"
                      v-model="row.leadComments"
                      :disabled="disableCertainParts"
                    />
                  </td>
                  <td
                    style="
                      word-wrap: break-word;
                      max-width: 1px;
                      white-space: normal;
                    "
                  >
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/delete.png"
                      alt="Delete"
                      @click="deleteRow(index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <h3 class="mb-3">Section B: Skills/Technologies</h3>
          <p>
            List the skills/technologies that the appraisee set out to achieve
            in the past 12 months (or the period covered by this appraisal) -
            against each skill/technology, the appraisee must enter the project
            that the evidence will be demonstrated and verified against.
          </p>
          <p>
            Score the performance against each objective (1-3 = poor, 4-6 =
            satisfactory, 7-9 = good, 10 = excellent)
          </p>
          <div
            class="add-row-block position-relative cursor-pointer"
            @click="addNewRowB"
          >
            <img src="../../assets/img/create.png" alt="Add row" />
            <div class="tool-tip">Add Row</div>
          </div>
          <div class="table-container" style="overflow: auto">
            <table class="table table-bordered">
              <thead class="thead-grey">
                <tr>
                  <th>Skill/Technology (To be completed by Employee)</th>
                  <th>Project (To be completed by Employee)</th>
                  <th>Evidence (To be completed by Employee)</th>
                  <th>Score (To be completed by Employee)</th>
                  <th>Lead Score (To be completed by Lead)</th>
                  <th>Comments (To be completed by Lead)</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in sectionBRows" :key="index">
                  <td>
                  <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.skillTechnology"
                      :class="{
                        'is-invalid': validationErrorsB[index]?.skillTechnology,
                      }"
                      @input="clearValidationErrorB(index, 'skillTechnology')"
                      :id="'skillTechnology-' + index"
                    />
                    <div
                      v-if="validationErrorsB[index]?.skillTechnology"
                      class="invalid-feedback"
                    >
                      Field is required
                    </div>
                  </td>
                  <td>
                     <input
                      type="text"
                      class="form-control light-border"
                      v-model="row.project"
                      :class="{
                        'is-invalid': validationErrorsB[index]?.project,
                      }"
                      @input="clearValidationErrorB(index, 'project')"
                      :id="'project-' + index"
                    />
                    <div
                      v-if="validationErrorsB[index]?.project"
                      class="invalid-feedback"
                    >
                      Field is required
                    </div>
                  </td>
                  <td>
                    <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.evidence"
                      :class="{
                        'is-invalid': validationErrorsB[index]?.evidence,
                      }"
                      @input="clearValidationErrorB(index, 'evidence')"
                      :id="'evidence-' + index"
                    />
                    <div
                      v-if="validationErrorsB[index]?.evidence"
                      class="invalid-feedback"
                    >
                      Field is required
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="1"
                      max="10"
                      class="form-control light-border"
                      v-model="row.employeeScore"
                      :class="{
                        'is-invalid': validationErrorsB[index]?.employeeScore,
                      }"
                      @input="handleInput(index, 'employeeScore')"
                      :id="'employeeScore-' + index"
                    />
                    <div
                      v-if="validationErrorsB[index]?.employeeScore"
                      class="invalid-feedback"
                    >
                      Field is required
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="1"
                      max="10"
                      class="form-control light-border"
                      v-model="row.leadScore"
                      :disabled="disableCertainParts"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                    
                      class="form-control light-border"
                      v-model="row.leadComments"
                      :disabled="disableCertainParts"
                    />
                  </td>
                  <td>
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/delete.png"
                      alt="Delete"
                      @click="deleteRowB(index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Section C -->
        <div class="col-md-12 mb-4">
          <h3>Section C: Performance Review Questions</h3>
          <div
            class="table-container"
            style="overflow: auto; max-height: 300px"
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Questions</th>
                  <th>Employee Response</th>
                  <th>Lead Response</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(question, index) in sectionCData"
                  :key="index"
                >
                  <tr>
                    <td
                      :colspan="question.contents.length > 1 ? 3 : 2"
                      style="
                        word-wrap: break-word;
                        max-width: 1%;
                        white-space: normal;
                      "
                    >
                      <strong>{{ question.tableDataHeader }}</strong>
                    </td>
                  </tr>
                  <tr
                    v-for="(content, contentIndex) in question.contents"
                    :key="contentIndex"
                  >
                    <td
                      style="
                        word-wrap: break-word;
                        max-width: 1%;
                        white-space: normal;
                      "
                    >
                      {{ content }}
                    </td>
                    <td
                      style="
                        word-wrap: break-word;
                        max-width: 1%;
                        white-space: normal;
                      "
                    >
                      <label>
                        <input
                          ref="employeeScoreInput_{{index}}_{{contentIndex}}"
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .employeeScore
                          "
                          :value="'Yes'"
                          :name="'employeeResponse' + index + contentIndex"
                          @input="
                            clearValidationErrorC(
                              index,
                              contentIndex,
                              'employeeScore'
                            )
                          "
                        />Yes</label
                      >
                      <label>
                        <input
                          ref="employeeScoreInput_{{index}}_{{contentIndex}}"
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .employeeScore
                          "
                          :value="'No'"
                          :name="'employeeResponse' + index + contentIndex"
                          @input="
                            clearValidationErrorC(
                              index,
                              contentIndex,
                              'employeeScore'
                            )
                          "
                        />

                        <!-- Similar setup for lead score inputs -->

                        No
                      </label>
                      <div
                        v-if="
                          validationErrorsC[
                            `employeeScore_${index}_${contentIndex}`
                          ]
                        "
                        class="error-message"
                      >
                        {{
                          validationErrorsC[
                            `employeeScore_${index}_${contentIndex}`
                          ]
                        }}
                      </div>
                    </td>
                    <td
                      v-if="question.contents.length > 1"
                      style="
                        word-wrap: break-word;
                        max-width: 1%;
                        white-space: normal;
                      "
                    >
                      <label>
                        <input
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .leadScore
                          "
                          :value="'Yes'"
                          :name="'leadResponse' + index + contentIndex"
                          :disabled="disableCertainParts"
                          @input="
                            clearValidationErrorC(
                              index,
                              contentIndex,
                              'leadScore'
                            )
                          "
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .leadScore
                          "
                          :value="'No'"
                          :name="'leadResponse' + index + contentIndex"
                          :disabled="disableCertainParts"
                          @input="
                            clearValidationErrorC(
                              index,
                              contentIndex,
                              'leadScore'
                            )
                          "
                        />
                        No
                      </label>
                      <div
                        v-if="
                          validationErrorsC[
                            `leadScore_${index}_${contentIndex}`
                          ]
                        "
                        class="error-message"
                      >
                        {{
                          validationErrorsC[
                            `leadScore_${index}_${contentIndex}`
                          ]
                        }}
                      </div>
                    </td>
                    <td
                      v-else
                      style="
                        word-wrap: break-word;
                        max-width: 1%;
                        white-space: normal;
                      "
                    >
                      <label>
                        <input
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .leadScore
                          "
                          :value="'Yes'"
                          :name="'leadResponse' + index + contentIndex"
                          :disabled="disableCertainParts"
                          @input="
                            clearValidationErrorC(
                              index,
                              contentIndex,
                              'leadScore'
                            )
                          "
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .leadScore
                          "
                          :value="'No'"
                          :name="'leadResponse' + index + contentIndex"
                          :disabled="disableCertainParts"
                          @input="
                            clearValidationErrorC(
                              index,
                              contentIndex,
                              'leadScore'
                            )
                          "
                        />
                        No
                      </label>
                      <div
                        v-if="
                          validationErrorsC[
                            `leadScore_${index}_${contentIndex}`
                          ]
                        "
                        class="error-message"
                      >
                        {{
                          validationErrorsC[
                            `leadScore_${index}_${contentIndex}`
                          ]
                        }}
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <h3 class="mb-3">Section D: Explanation of High Scores</h3>
          <p>
            List and give an explanation of the points where you have given a
            high score.
          </p>
          <div
            class="add-row-block position-relative cursor-pointer"
            @click="addNewRowD"
          >
            <img src="../../assets/img/create.png" alt="Add row" />
            <div class="tool-tip">Add Row</div>
          </div>
          <div class="table-container" style="overflow: auto">
            <table class="table table-bordered">
              <thead class="thead-grey">
                <tr>
                  <th>Section (To be completed by Employee)</th>
                  <th>Evaluation Area/Skill (To be completed by Employee)</th>
                  <th>Explanation (To be completed by Employee)</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in sectionDRows" :key="index">
                  <td>
                     <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.skill"
                      :class="{ 'is-invalid': validationErrorsD[index]?.skill }"
                      @input="clearValidationErrorD(index, 'skill')"
                      :id="'skill-' + index"
                    />
                    <div
                      v-if="validationErrorsD[index]?.skill"
                      class="invalid-feedback"
                    >
                      Field is required
                    </div>
                  </td>
                  <td>
                    <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.evaluationArea"
                      :class="{
                        'is-invalid': validationErrorsD[index]?.evaluationArea,
                      }"
                      @input="clearValidationErrorD(index, 'evaluationArea')"
                      :id="'evaluationArea-' + index"
                    />
                    <div
                      v-if="validationErrorsD[index]?.evaluationArea"
                      class="invalid-feedback"
                    >
                      Field is required
                    </div>
                  </td>
                  <td>
                    <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.explanation"
                      :class="{
                        'is-invalid': validationErrorsD[index]?.explanation,
                      }"
                      @input="clearValidationErrorD(index, 'explanation')"
                      :id="'explanation-' + index"
                    />
                    <div
                      v-if="validationErrorsD[index]?.explanation"
                      class="invalid-feedback"
                    >
                      Field is required
                    </div>
                  </td>
                  <td>
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/delete.png"
                      alt="Delete"
                      @click="deleteRowD(index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-12 mb-4" ref="sectionE">
          <h3 class="mb-3">
            Section E: Provide comments on achievements, failures, areas needing
            improvement, and goals for the next year.
          </h3>

          <div class="form-group">
            <label for="leadComments"><strong> Lead Comments:</strong></label>
            <textarea
              id="leadComments"
              class="form-control light-border"
              rows="5"
              v-model="sectionERows.teamLeadComments"
              :disabled="disableCertainParts"
              :class="{ 'is-invalid': validationErrorsE.teamLeadComments }"
              @input="clearValidationErrorE(index, 'teamLeadComments')"
            ></textarea>
            <!-- <div v-if="validationErrorsE.teamLeadComments" class="invalid-feedback">Field is required</div> -->
          </div>

          <div class="form-group">
            <label for="achievementsFailures"
              ><strong>Achievements and Failures:</strong></label
            >
            <textarea
              id="achievementsFailures"
              class="form-control light-border"
              rows="5"
              v-model="sectionERows.achievementsFailures"
              :class="{ 'is-invalid': validationErrorsE.achievementsFailures }"
              @input="clearValidationErrorE('achievementsFailures')"
            ></textarea>
            <div
              v-if="validationErrorsE.achievementsFailures"
              class="invalid-feedback"
            >
              Field is required
            </div>
          </div>

          <div class="form-group">
            <label for="improvementNeeded"
              ><strong>Improvement Needed:</strong></label
            >
            <textarea
              id="improvementNeeded"
              class="form-control light-border"
              rows="5"
              v-model="sectionERows.improvementNeeded"
              :class="{ 'is-invalid': validationErrorsE.improvementNeeded }"
              @input="clearValidationErrorE('improvementNeeded')"
            ></textarea>
            <div
              v-if="validationErrorsE.improvementNeeded"
              class="invalid-feedback"
            >
              Field is required
            </div>
          </div>

          <div class="form-group">
            <label for="goalsNextYear"
              ><strong>Goals For Next Year:</strong></label
            >
            <textarea
              id="goalsNextYear"
              class="form-control light-border"
              rows="5"
              v-model="sectionERows.goalsForNextYear"
              :class="{ 'is-invalid': validationErrorsE.goalsForNextYear }"
              @input="clearValidationErrorE('goalsForNextYear')"
            ></textarea>
            <div
              v-if="validationErrorsE.goalsForNextYear"
              class="invalid-feedback"
            >
              Field is required
            </div>
          </div>
        </div>

        <!-- <div class="col-md-12 mb-4">
          <h3 class="mb-3">
            *Need to complete the following section only after the completion of
            the appraisal form from both employee and Lead/employer level.
          </h3>

          <table class="table table-bordered">
            <thead class="thead-grey">
              <tr>
                <th>Questions</th>
                <th>Employee</th>
                <th>Lead</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Have you had discussion with your lead or employer about your
                  appraisal before signing off:
                </td>
                <td>
                  <label class="radio-inline">
                    <input
                      type="radio"
                      v-model="sectionERows.discussionWithLead.employeeResponse"
                      value="Yes"
                      :class="{
                        'is-invalid': validationErrorsE.employeeResponse,
                      }"
                      name="employeeResponse"
                    />
                    Yes
                  </label>
                  <label class="radio-inline">
                    <input
                      type="radio"
                      v-model="sectionERows.discussionWithLead.employeeResponse"
                      value="No"
                      :class="{
                        'is-invalid': validationErrorsE.employeeResponse,
                      }"
                      name="employeeResponse"
                    />
                    No
                  </label>
                  (To be completed by Employee)
                  <div
                    v-if="validationErrorsE.employeeResponse"
                    class="invalid-feedback"
                    style="display: block; font-size: 0.8rem"
                  >
                    Please select an option
                  </div>
                </td>
                <td>
                  <label class="radio-inline">
                    <input
                      type="radio"
                      v-model="sectionERows.discussionWithLead.leadResponse"
                      value="Yes"
                      :disabled="disableCertainParts"
                    />
                    Yes
                  </label>
                  <label class="radio-inline">
                    <input
                      type="radio"
                      v-model="sectionERows.discussionWithLead.leadResponse"
                      value="No"
                      :disabled="disableCertainParts"
                    />
                    No
                  </label>
                  (To be completed by Lead)
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </template>

    <!-- Submit buttons -->
    <div class="col-12 text-center my-2">
      <span
        type="button"
        tabindex="0"
        class="submit_btn px-5 py-2 mr-2"
        v-if="!disableSaveAndSignButton"
        @click="showPopupvalid('Emp_Submit')"
        >Save &amp; Sign</span
      >
      <span
        type="button"
        tabindex="0"
        class="submit_btn px-5 py-2"
        v-if="!disableSaveButton"
        @click="saveData('Save')"
      >
        <span v-if="loadingSaveBlock && !errorMsg">
          <img
            src="../../assets/img/loading.webp"
            width="20"
            class="loading-img mr-2"
            alt="Login buffering"
          />
        </span>
        Save
      </span>
    </div>
  </div>
  <div class="password-popup" v-if="showpopup">
    <div class="modal-width">
      <div class="modal-content">
        <div class="password-popup-header d-flex justify-content-between p-3">
          <h5 class="my-0 align-self-center">Sign Document</h5>
          <span
            type="button"
            class="align-self-center"
            @click="closePopup()"
            aria-hidden="true"
          >
            <i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body modal-overflow">
          <form>
            <div class="form-group mb-4">
              <h5>
                !!!On signing, this is equivalent to the wet ink signature!!!
              </h5>
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                v-model="emailId"
                @input="clearPopupFieldError('emailId')"
              />
              <small v-if="popupErrors.emailId" class="text-danger">{{
                popupErrors.emailId
              }}</small>
            </div>
            <div class="form-group mb-4 position-relative">
              <input
                placeholder="Please enter your Digi-sign password"
               
                class="form-control"
                 :type="inputTypes"
                id="templatePassword"
               name="password"
                v-model="templatePassword"
                @input="clearPopupFieldError('templatePassword')"
              />
              <i
                class="password-visible-block cursor-pointer position-absolute"
              
                :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
                 @click.prevent="passwordVisible()"
              ></i>
              <small v-if="popupErrors.templatePassword" class="text-danger">{{
                popupErrors.templatePassword
              }}</small>
            </div>
            <div class="form-group mb-4">
              <label class="mb-0" for="reasons">Reason</label>
              <select
                class="form-control"
                v-model="reasons"
                id="reasons"
                @change="clearPopupFieldError('reasons')"
              >
                <option value="" disabled selected>-Select Reason-</option>
                <option
                  v-for="reason in reasonList"
                  :key="reason.id"
                  :value="reason.reason"
                >
                  {{ reason.reason }}
                </option>
              </select>
              <input
                class="form-control mt-2"
                v-if="reasons === 'Others'"
                maxlength="50"
                v-model="otherReason"
                type="text"
                placeholder="Enter other reason"
              />
              <small v-if="popupErrors.reasons" class="text-danger">{{
                popupErrors.reasons
              }}</small>
            </div>
            <div class="text-center">
              <span
                type="button"
                tabindex="0"
                class="submit_btn px-5 py-2"
                :disabled="loadingBlock"
                @click.prevent="saveData('Emp_Submit')"
              >
                <span v-if="loadingBlock && !errorMsg">
                  <img
                    src="../../assets/img/loading.webp"
                    width="20"
                    class="loading-img mr-2"
                    alt="Login buffering"
                  />
                </span>
                <span v-else>Submit</span>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./empAssesment"></script>

<style scoped>
/* Add your component-specific styles here */
.light-border {
  border-color: #cccccc; /* Light gray color */
}
.password-popup {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.is-invalid {
  border-color: red;
}
.text-danger {
  color: red;
}


</style>
