<template>
  <div
    class="pace-done"
    :class="{ 'mini-navbar': isminiActive, 'body-small': isbodySmall }"
  >
    <div id="wrapper">
      <nav
        v-if="getNavbarstatus && getAuthorize && (getshowsideNavbarstatus != 'false' &&  getshowsideNavbarstatus)"
        id="mobilemenu"
        class="navbar-default navbar-static-side"
        role="navigation"
      >
        <div>
          <ul class="nav metismenu">
            <li class="nav-header text-center p-2">
              <img
                class="align-self-center"
                src="../../assets/img/DMS_logo.png"
                alt="DMS brand logo"
                width="60"
              />
            </li>
            <li class="border-bottom" >
              <router-link
                class="d-flex flex-column align-items-center"
                to="/dashboard"
              >
                <img
                  src="../../assets/img/menu-icons/dashboard.png"
                  alt="dashboard menu"
                />
                <span class="nav-label">Dashboard</span>
              </router-link>
            </li>
            <li class="border-bottom" v-if="designationPrivileges.includes('Main Menu My Files')">
              <router-link
                class="d-flex flex-column align-items-center"
                to="/generalFiles"
              >
                <img
                  src="../../assets/img/menu-icons/dashboard.png"
                  alt="dashboard menu"
                />
                <span class="nav-label">My Files</span>
              </router-link>
            </li>
            <li class="border-bottom" v-if="designationPrivileges.includes('Main Menu Projects')">
              <router-link 
                class="d-flex flex-column align-items-center"
                to="/projectList"
                ><img
                  src="../../assets/img/menu-icons/projects.png"
                  alt="projects menu"
                /><span class="nav-label">Projects</span>
              </router-link>
            </li>
            <li class="border-bottom" v-if="designationPrivileges.includes('Main Menu Departments')" >
              <router-link 
                class="d-flex flex-column align-items-center"
                to="/department"
                ><img
                  src="../../assets/img/menu-icons/departments.png"
                  alt="departments menu"
                /><span class="nav-label">Departments</span>
              </router-link>
            </li>
            <li class="border-bottom" v-if="designationPrivileges.includes('Main Menu Training')">
              <router-link 
                class="d-flex flex-column align-items-center"
                to="/training"
                ><img
                  src="../../assets/img/menu-icons/projects.png"
                  alt="projects menu"
                /><span class="nav-label">Training</span>
              </router-link>
            </li> 
            <li class="border-bottom" v-if="designationPrivileges.includes('Main Menu Administration')">
              <router-link 
                class="d-flex flex-column align-items-center"
                to="/administration"
                ><img
                  src="../../assets/img/menu-icons/projects.png"
                  alt="projects menu"
                /><span class="nav-label">Administration</span>
              </router-link>
            </li>           
            <li class="border-bottom" v-if="designationPrivileges.includes('Main Menu Approvals')">
              <router-link
                class="d-flex flex-column align-items-center"
                to="/document_approval"
                ><img
                  src="../../assets/img/menu-icons/approvals.png"
                  alt="designation menu"
                /><span class="nav-label">Approvals</span>
              </router-link>
            </li>
            <li class="border-bottom" v-if="designationPrivileges.includes('Main Menu Assessment')">
              <router-link
                class="d-flex flex-column align-items-center"
                to="/Assessment"
                ><img
                  src="../../assets/img/menu-icons/assesment.png"
                  alt="/Assessment"
                /><span class="nav-label">Assessment</span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
      <div id="page-wrapper" class="col-md-12">
        <div class="row border-bottom" v-if="getNavbarstatus && getAuthorize">
          <nav class="navbar navbar-static-top" role="navigation">
            <div class="navbar-header">
              <a
                class="navbar-minimalize minimalize-styl-2"
                @click.prevent="showSideBar()"
                ref="#"
                ><i class="text-white fa fa-bars"></i>
              </a>
            </div>
            <ul class="nav navbar-top-links navbar-right" >
              <li class="mr-3" v-if="getshowsideNavbarstatus != 'false' &&  getshowsideNavbarstatus">
                <a
                  class="count-info position-relative"
                  data-toggle="dropdown"
                  href="#"
                  aria-expanded="false"
                >
                  <img
                    class="profile-block cursor-pointer"
                    src="../../assets/img/notification.png"
                    alt="User profile"
                  />
                  <span class="position-absolute notification-count">
                    <span class="label label-warning">{{
                      notificationCount
                    }}</span>
                  </span>
                </a>
                <ul class="dropdown-menu dropdown-alerts px-3 height-scroll">
                  <li>
                    <div
                      class="media-body cursor-pointer"
                      v-for="list in notificationalert"
                      :key="list.documentId"
                    >
                      <span v-if="list.exam != true">
                        <div
                          @click.prevent="
                            approvalScreen(
                              list.documentId,
                              list.version,
                              list.versionId
                            )
                          "
                        >
                          <span> The document </span>
                          <strong>{{ list.documentName }}</strong>
                          <span> is assigned for approval by </span>
                          <strong>{{ list.assignedBy }}</strong>
                          <li class="dropdown-divider"></li>
                        </div>
                      </span>
                      <span v-if="list.exam == true">
                        <div>
                          <span>
                            An online test has been assigned to you against the
                            document
                          </span>
                          <strong>{{ list.documentName }}</strong>
                          <li class="dropdown-divider"></li>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div class="text-center">
                      <a class="dropdown-item" @click="approvalScreenlist()">
                        <strong>See All Alerts</strong>
                        <i class="fa fa-angle-right"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </li>
              <!-- users -->
              <li class="mr-3">
                <!-- <a href="login.html"> -->
                <img
                  class="profile-block position-relative cursor-pointer"
                  @click="profileBlock = !profileBlock"
                  src="../../assets/img/profile.png"
                  title="User profile"
                  width="20"
                  height="20"
                />
                <!-- </a> -->
                <div
                  class="profile-details text-start py-1"
                  v-on:clickout="profileBlock = !profileBlock"
                  v-if="profileBlock"
                >
                  <div v-if="getshowsideNavbarstatus != 'false' &&  getshowsideNavbarstatus" class="d-flex px-4 mb-1 py-1" @click="GotoProfile()">
                    <img src="../../assets/img/pro.png" alt="profile icon" class="mr-2">
                    <div class="details-block" @click="GotoProfile()">
                      Profile
                    </div>
                    
                    <!-- <div class="hr"></div> -->
                  </div>
                  <div v-if="getshowsideNavbarstatus != 'false' &&  getshowsideNavbarstatus" class="hr-line-dashed my-0"></div>
                  <div class="d-flex px-4 mb-1 py-1" @click="logout">
                    <img src="../../assets/img/log.png" alt="info icon" class="mr-2">
                    <div class="details-block" @click="logout">Logout</div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, onMounted, onUnmounted } from "vue";
import auth0 from "auth0-js";
import axios from "axios";
// import createAuth0Client from "@auth0/auth0-spa-js";

import { useRoute, useRouter } from "vue-router";
let webAuth = new auth0.WebAuth({
  domain: process.env.VUE_APP_Domain,
	clientID: process.env.VUE_APP_Client_ID,
});
export default {
  name: "Navbar",
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    let baseurl = ref(process.env.VUE_APP_Service_URL);
    let mainapi = ref(process.env.VUE_APP_Service_URL);
    let windowWidth = ref(window.innerWidth);
    let searchtext = ref("");
    let urlstring = ref("");
    let profileBlock = ref(false);
    let collapseBlock = ref(true);
    let isminiActive = ref(false);
    let isbodySmall = ref(false);
    let notificationCount = ref();
    let notificationalert = ref();
    let approvalNotificationDetails = ref();
    let getNavbarstatus = computed(() => {
      return store.getters.getNavbarstatus;
    });
    let getshowsideNavbarstatus = computed(() => {
      return store.getters.getSideNavbarstatus;
    });
    let getAuthorize = computed(() => {
      getNotificationCount();
      if (store.getters.getAuthorize) {
        store.dispatch("setNavbar", true);
      }
      return store.getters.getAuthorize;
    });
    onMounted(() => {
  
      onchecktologout();
      getNotificationCount();
      onWidthChange();
      window.addEventListener("resize", onWidthChange);
    });
    onUnmounted(() => window.removeEventListener("resize", onWidthChange));
    function onWidthChange() {
      windowWidth.value = window.innerWidth;
      if (windowWidth.value < 768) {
        isbodySmall.value = true;
        isminiActive.value = false;
        getNotificationCount();
      } else isbodySmall.value = !isbodySmall.value;
    }
    async function getNotificationCount() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${mainapi.value}document/document/approvalnotification?days=7`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          notificationalert.value = res.data.approvalNotificationDetails;
          approvalNotificationDetails.value =
            res.data.approvalNotificationDetails;
          notificationCount.value = res.data.notificationCount;
        })
        .catch(async (err) => {
          console.log(err);
        });
    }
    function approvalScreen(id, version) {
      store.dispatch("setDocViewSource", "Approval");
      router.push(`/documentview/` + id + "/" + version );
    }
    function approvalScreenlist() {
      router.push(`/document_approval`);
    }
    function GotoProfile() {
      profileBlock.value = false;
      router.push(`/profile_settings`);
    }
    async function onchecktologout() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${baseurl.value}account-core/user/get`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("sublist is", res);
        })
        .catch(async (err) => {
          if (err.response.status == "401") {
            urlstring.value = location.href.substring(
              location.href.indexOf("?") + 1
            );
            console.log("Link is", urlstring.value);
            //token expired..
            if (store.getters.getDocLog == true) {
              router.push(`/doclogin/?${urlstring.value}`);
            } else if (store.getters.getApprovalLog == true) {
              router.push(`/approvallogin/?${urlstring.value}`);
            } else if (store.getters.getUserLog == true) {
              router.push(`/confirmUserInfo/${store.getters.getUserToken}`);
            } else {
              localStorage.clear();
              store.dispatch("setToken", null);
              if (getNavbarstatus.value) {
                router.push("/");
              } else {
                router.push(route.path);
              }
            }
          }
        });
    }
    function showSideBar() {
      isminiActive.value = !isminiActive.value;
    }
    function rotate(value) {
      let rotateArrow = document.getElementById(value);
      if (collapseBlock.value == false) {
        rotateArrow.style.rotate = "180deg";
      } else rotateArrow.style.rotate = "0deg";
    }
    async function returntologin() {
      let a = localStorage.clear();
      let b = webAuth.logout({
        clientID: process.env.VUE_APP_Client_ID,
      });
    }
    async function logout() {
      // profileBlock.value = !profileBlock.value
      // store.dispatch("setToken",null);
      // store.dispatch("setAuthorize", false);
      // store.dispatch("setNavbar", false);
      // localStorage.clear();
      // router.push(`/`);
      let a = localStorage.clear();
      let b = webAuth.logout({
        returnTo: `${process.env.VUE_APP_logout_URL}`,
        clientID: process.env.VUE_APP_Client_ID,
      });
    }
    
    let designationPrivileges = computed(function () {
      return  this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
      //return store.getters.getRolesprivilegeData;
    });
    return {
      designationPrivileges,
      getNavbarstatus,
      getshowsideNavbarstatus,
      getAuthorize,
      showSideBar,
      profileBlock,
      collapseBlock,
      rotate,
      logout,
      onWidthChange,
      isminiActive,
      isbodySmall,
      approvalNotificationDetails,
      notificationCount,
      notificationalert,
      approvalScreen,
      approvalScreenlist,
      getNotificationCount,
      GotoProfile,
      urlstring,
      screen,
    };
  },
};
/* eslint-disable */
</script>

<style scoped>
@import "../../assets/css/style.css";
.angle-down-right {
  float: right;
}

@media (max-width: 768px) {
  .mini-navbar .pagewraphalf {
    width: calc(100% - 70px);
  }
  .mini-navbar .pagewrapfull {
    width: 100%;
  }
  .navbar.navbar-static-top {
    padding: 0 1rem !important;
  }

  .navbar-header {
    align-self: center;
  }

  .minimalize-styl-2 {
    padding: 4px 12px;
    font-size: 20px;
    float: left;
  }

  .nav > li > a i {
    font-size: 1em;
  }

  #wrapper {
    position: relative !important;
  }
}
/* .nav.navbar-top-links > li > a:hover,
.nav.navbar-top-links > li > a:focus {
  color: #ffffff !important;
} */

.animate {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.nav-link:hover,
.nav-link:focus {
  color: #d7deea !important;
}

ul.nav-second-level {
  background: #e0f9ff;
  text-decoration: none;
}

.nav-label {
  padding-top: 9px;
  font-size: 10px;
}

.profile-details {
  position: absolute;
  right: 10px;
  top: 60px;
  background-color: #ffffff;
  width: min(151px, 100vw);
  border-radius: 3px;
  z-index: 100;
  box-shadow: 0 -2px 5px rgb(0 0 0 / 15%), 0 9.5px 13.5px rgb(0 0 0 / 8%);
  -webkit-box-shadow: 0 -2px 5px rgb(0 0 0 / 15%),
    0 9.5px 13.5px rgb(0 0 0 / 8%);
  -moz-box-shadow: 0 -2px 5px rgb(0 0 0 / 15%), 0 9.5px 13.5px rgb(0 0 0 / 8%);
}

.profile-details > * {
  cursor: pointer;
}

#admin-arrow {
  right: -60px;
  transition: rotate 200ms ease-in-out 0s;
}

.colorblue {
  color: #0094ff;
}
.dropdown1,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.notification-count {
  right: 0px;
  top: 0px;
}
.profile-details > *:is(:hover, :focus) {
  background-color: #ebebeb;
}

.height-scroll {
  height: 250px;
  overflow-y: auto;
}
</style>
