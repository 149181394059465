<template>
  <div>
    <!-- <div class="ibox-title style_2 d-flex justify-content-between">
      <h5>My assessment</h5>
      <div class="configure-block"></div>
    </div> -->
    <div class="form-table-view-container">
      <div class="table-responsive">
        <table class="table table-bordered training-plan">
          <thead>
            <tr>
              <th>Document Type</th>
              <!-- <th>Employee ID</th> -->
              <!-- <th>Designation</th> -->

              <th>Date of Review</th>
              <th>Team Lead</th>
              <th>TL Status</th>
              <th>Secondary TL</th>
              <th>Status</th>
              <!-- <th>Status</th> -->
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody v-if="assessmentData">
            <tr
              v-for="(assessment, index) in assessmentData"
              :key="assessment.item.id"
            >
              <td>{{ "Assesment" }}</td>
              <!-- <td>{{ assessmentData.item.empdetailsTable.empId }}</td> -->
              <!-- <td>{{ assessmentData.item.empdetailsTable.designation }}</td> -->

              <td>{{ assessment.item.empdetailsTable.dateOfReview }}</td>
              <td>{{ assessment.teamLead }}</td>
              <td>
                <span v-if="assessment.totalStatus">{{
                  assessment.totalStatus.lead.status
                }}</span>
                <span v-else>Pending</span>
              </td>
              <td>
                {{
                  assessment.item.empdetailsTable.secondaryTeamLead
                    ? assessment.secondaryTL
                    : "NIL"
                }}
              </td>
              <td>
                {{
                  assessment.item.empdetailsTable.secondaryTeamLead
                    ? assessment.item.totalStatus &&
                      assessment.item.totalStatus.secondaryLead
                      ? assessment.item.totalStatus.secondaryLead.status
                      : "Pending"
                    : "NIL"
                }}
              </td>

              <td class="text-center">
                <div
                  class="position-relative cursor-pointer"
                  @click.prevent="showSidePopup[index] = !showSidePopup[index]"
                  v-on:clickout="showSidePopup[index] = false"
                >
                  <i class="fa fa-ellipsis-v"></i>
                </div>
                <div class="plan-side-popup" v-if="showSidePopup[index]">
                  <!-- Edit action -->
                  <div
                    v-if="
                      !assessment.item.totalStatus ||
                      assessment.item.totalStatus.emp.status !== 'Completed'
                    "
                    @click="editAssessment(assessment.item.id)"
                  >
                    Edit
                  </div>
                  <!-- Download action -->
                  <div
                    v-else-if="
                      assessment.item.totalStatus &&
                      assessment.item.totalStatus.hr === 'Verified'
                    "
                    @click="downloadAssessment(assessment.item.id)"
                  >
                    Download
                  </div>
                  <!-- Fallback message if neither condition is met -->
                  <div v-else>Nothing to show</div>
                </div>
              </td>

              <!-- Add more table cells for other data as needed -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-3 mx-0">
      <div class="col-md-12 mx-0 px-0">
        <div
          class="dataTables_paginate paging_simple_numbers pagination float-right"
        >
          <div class="col-md-12 d-flex justify-content-md-end px-0">
            <VPagination
              v-model="currentPage"
              :pages="totalPages"
              @update:modelValue="loadPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./myAssesment"></script>

<style scoped>
.plan-side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(109px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup > div {
  cursor: pointer;
  padding: 0.5rem;
}
.training-plan thead th {
  font-weight: bold;
}

.plan-side-popup > div:hover {
  background-color: #e4eaf9;
}
</style>
